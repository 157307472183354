import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { ImFilePdf } from "react-icons/im";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import axios from "axios";
import { BASE_URL, getThanksDetails } from "../../../redux/constants/ApiEndPoints";
import ReactHtmlParser from "react-html-parser"

import "./order_placed.scss";

export default function OrderPlaced(props) {
    const [ pageDetailsState, setPageDetailsState ] = useState({})
    
    const { id } = props;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {
        axios.get(BASE_URL + getThanksDetails, {headers}).then(response => {
            setPageDetailsState(response.data.data)
        }).catch(error => console.log(error))
    }, [])

    return(
        <div>
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <Grid container justifyContent = "center">
                        <p className = "font-[dmserif] text-[35px] text-[#404040]">{pageDetailsState?.title}</p>
                        <div className="linkDiv">
                            <p className = "font-[montserrat] text-[14px] text-[#404040] mt-[60px]">{pageDetailsState?.id && ReactHtmlParser(pageDetailsState?.text)}</p>
                        </div>
                    </Grid>
                    {/* <Grid container justifyContent = "space-between" alignItems = "center" className = "mt-[76px]">
                        <div className = "flex items-center">
                            <ImFilePdf size = "1.5em" color = "#c98468" />
                            <p className = "font-[montserrat] font-bold text-[16px] text-[#404040] ml-[38px]">Bestelling nr {id}</p>
                        </div>
                        <div className = "flex items-center">
                            <AiOutlinePrinter size = "1.75em" color = "#c98468" />
                            <div className = "ml-[15px]">
                                <BsDownload size = "1.5em" color = "#c98468" />
                            </div>
                        </div>
                    </Grid> */}
                </Container>
            </Grid>
        </div>
    )
}