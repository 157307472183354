import React, { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import axios from "axios";
import { BASE_URL, sign_in, forgot_password } from '../../../redux/constants/ApiEndPoints';
import LoginTopBar from '../../../components/top_bars/login_top_bar';
import InputText from '../../../components/inputs/text';
import PropTypes from "prop-types";
import LockModal from '../../../components/lock_modal/lock_modal';

import './login.scss';
import ForgetPassword from "./forgotPassword";
import { REGEX } from '../../../constants/constants';

const LoginPage = (props) => {
    const [forgotPasswordModalState, setForgotPasswordModalState] = useState(false)
    const [errorStates, setErrorStates ] = useState({
        email: false,
        password: false,
        success: false
    })
    const navigate = useNavigate();
    const emailInputRef = useRef(0);
    const passwordInputRef = useRef(0);

    const loginLabelClass = 'font-montserrat font-semibold text-[14px]';
    const loginInputClass = 'border border-[#000000] w-full mt-[10px] py-2 px-3';
    const cardParentClass = 'text-center px-[25px] md:px-[115px] h-fit login_card_parent';
    const mainHeadingClass = 'text-[30px] font-dmserif login_main_heading';
    const subHeadingClass = 'text-[16px] mb:[15px] md:mb-[45px] font-montserrat login_sub_heading';
    const buttonClass = "text-[18px] h-[45px] w-[220px] font-montserrat bg-redOrange-100 hover:bg-redOrange-100 text-white font-semibold rounded-full"
    const { navigationPath } = props;

    const onLoginBtnClicked = () => {
        const email = emailInputRef.current.getValue();
        const password = passwordInputRef.current.getValue();
        if(validateForm(email, password)) {
            const payload = {
                email,
                password,
                type: 1
            }
            axios.post(BASE_URL + sign_in, payload).then(response => {
                const token = response.data.data.info.auth.token;
                let userData = {...response.data.data.info}
                delete userData.auth;
                localStorage.setItem('token', token);
                localStorage.setItem('user-data', JSON.stringify(userData));
                navigationPath ? navigate(navigationPath) : navigate(0)
            }).catch(error => {
                if(error?.response?.data?.data?.message === "Invalid email or password") {
                    setErrorStates({email: true, password: true, success: true})
                }
            })
        }
    }  

    const validateForm = (email, password) => {
        const emailError = email ? REGEX.EMAIL?.test(email) : false;
        const isValid = emailError && password ? true : false;
        setErrorStates({
            email: !emailError,
            password: password ? false : true
        })
        return isValid;
    }

    const handleForgotPasswordModalClose = () => {
        setForgotPasswordModalState(false)
    }

    const NewRegistrationCard = () => {
        return (
            <div className={cardParentClass}>
                <div className={mainHeadingClass}>
                    Registreren
                </div>
                <div className={subHeadingClass}>
                    Indien je nog geen account hebt op deze website
                </div>
                <Link to='/register'>
                    <button className={`${buttonClass} mt-[75px] login_button`}>
                        Registreer
                    </button>
                </Link>
            </div>
        )
    }

    return (
        <div>
            <LoginTopBar isActive = {false} />    
            <Grid container justifyContent= "center" alignItems = "center">
                <div className='loginpage_body_parent mb:mb-[94px]'>
                    <div className={cardParentClass}>
                        <div className={mainHeadingClass}>
                            Log in
                        </div>
                        <div className={subHeadingClass}>
                            Indien je al een account  hebt  opt deze  website
                        </div>
                        <InputText 
                            label='EMAIL'
                            parentStyleProps='text-left'
                            labelStyleProps= {loginLabelClass}
                            type='text'
                            name='email'
                            inputStyleProps={loginInputClass}
                            ref = {emailInputRef}
                            errorState = {errorStates?.email}
                        />
                        <InputText 
                            label='WACHTWOORD'
                            parentStyleProps='text-left mt-[20px] mb-[26px]'
                            labelStyleProps= {loginLabelClass}
                            type='password'
                            name='password'
                            inputStyleProps={loginInputClass}
                            ref = {passwordInputRef}
                            errorState = {errorStates?.password}
                        />

                        <div className='underline font-montserrat text-[16px] mb-[45px] cursor-pointer' onClick = {() => setForgotPasswordModalState(true)}>
                            Wachtwoord vergeten?
                        </div>
                        <button onClick={onLoginBtnClicked} className={buttonClass}>
                            Aanmelden
                        </button>
                        {errorStates?.success && (
                            <p className = "font-montserrat font-regular text-[16px] text-[red] mt-[10px]">Email en/of wachtwoord is niet correct.</p>
                        )}
                        <ForgetPassword
                            forgotPasswordModalState={forgotPasswordModalState}
                            setForgotPasswordModalState={setForgotPasswordModalState}
                            handleForgotPasswordModalClose={handleForgotPasswordModalClose}
                        />
                    </div>
                    <NewRegistrationCard/>
                </div>
            </Grid>
        </div>
    )
}

export default LoginPage;

LoginPage.defaultProps = {
    navigationPath: null
}

LoginPage.propTypes = {
    navigationPath: PropTypes.string
}
