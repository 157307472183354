import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { connect } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { getAllProducts } from "../../redux/actions/product";
import Pagination from "../../components/pagination";
import BreadcrumbsItem from "./../../components/breadcrumb/breadcrumb";
import InventoryCard from "../../components/inventory_card/inventory_card";
import Filter from "./components/filter";
import { useLocation } from "react-router-dom";
import { debounce, filter } from "lodash";

import "./inventory.scss";
import StickyButton from "../../components/maakeenafspraak_sticky_button/sticky_button";

const sortList = [
  {
    id: 1,
    name: "Aanbeloven",
    value: "REC"
  },
  {
    id: 2,
    name: "Prijs oplopend",
    value: "ASC"
  },
  {
    id: 3,
    name: "Prijs aflopend",
    value: "DESC"
  },
  {
    id: 4,
    name: "Naam A-Z",
    value: "ASC"
  },
  {
    id: 5,
    name: "Naam Z-A",
    value: "DESC"
  },
  {
    id: 7,
    name: "Promoties",
    value: "true"
  },
]
const getBreadcrumb = (location) => {
  const query = window.location.search;
  const search = new URLSearchParams(query).get("search");
  const brandName = new URLSearchParams(query).get("brandName");
  const sort = new URLSearchParams(query).get("sort");
  if(search) {
    if(location?.state?.roomName) {
      return `${search} (${location?.state?.roomName})`
    }
    else {
      return `${search}`
    }
  }
  else if(brandName) return `${brandName}`;
  else if(sort) return `${sort}`;
}

const InventoryPage = ({ product, getAllProducts }) => {
  const [sortFilterState, setSortFilterState] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedSortValue, setSelectedSortValue] = useState(0)
  const [filterOptions, setFilterOptions] = useState({
    search: [],
    Woonstijl: [],
    Materiaal: [],
    Winkel: [],
    Merken: [],
    Ruimtes: [],
    price: [0, 55000],
  })
  const [ allowUpdate, setAllowUpdate ] = useState(false);
  const [ pageTitle, setPageTitle ] = useState("");

  const location = useLocation();
  const breakpoint = 1024;
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/inventory-page", label: "Products" },
      { to: "/inventory-page", label: getBreadcrumb(location), active: true },
    ],
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const resizeHandler = event => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    const query = window.location.search;
    if(query) {
      const parsedQuery = new URLSearchParams(query);
      const search = parsedQuery.get("search");
      const brand = parsedQuery.get("brand");
      const brandname = parsedQuery.get("brandname");
      const sort = parsedQuery.get("Nieuw");
      
      if(brand) {
        let filterOptionsCopy = {...filterOptions};
        filterOptionsCopy.Merken = [{
          id: brand,
          name: brandname
        }];
        setFilterOptions(filterOptionsCopy)
        filterData(filterOptionsCopy, true);
        setPageTitle(brandname)
      }
      else {
        filterData(filterOptions, true);
      }

      if(sort) {
        setPageTitle("Nieuw")
      }
      if(search) {
        if(location?.state?.roomName) {
          setPageTitle(`${search} (${location?.state?.roomName})`)
        }
        else {
          setPageTitle(search)
        }
      }
    }
    else {
      filterData(filterOptions, true);
    }
  }, []);

  useEffect(() => {
    if(allowUpdate) {
      filterData();
    }
  }, [filterOptions, selectedSortValue])

  useEffect(() => {
    let totalProducts = product.all_products?.total;
    let productsList = product.all_products?.data;
    let pages = Math.ceil(totalProducts / 20);
    setProducts(productsList);
    setPages(pages);
  }, [product.all_products]);
  
  const filterData = (filterOptionsNew, isInit, page) => {
    const urlParams = window.location.search;
    if(validateFilterOptions(filterOptionsNew || null)) {
      // create an array to pass filter options to axios, with proper body params
      let filterQuery = {};
      if(filterOptionsNew) {
        for(const option in filterOptionsNew) {
          if(filterOptionsNew[option].length > 0) {
            if(option === "Merken") {
              filterQuery.brand = [];
              filterOptionsNew[option].forEach(selectedBrand => {
                filterQuery.brand.push(selectedBrand?.id)
              })
            }
            else {
              filterQuery[convertFilterCategoryNames(option)] = filterOptionsNew[option];
            }
          }
        }
      }
      else {
        for(const option in filterOptions) {
          if(filterOptions[option].length > 0) {
            if(option === "Merken") {
              filterQuery.brand = [];
              filterOptions[option].forEach(selectedBrand => {
                filterQuery.brand.push(selectedBrand?.id)
              })
            }
            else {
              filterQuery[convertFilterCategoryNames(option)] = filterOptions[option];
            }
          }
        }
      }
      if(urlParams && !filterQuery?.search) {
        const parsedQuery = new URLSearchParams(urlParams);
        const search = parsedQuery.get("search");
        const room = parsedQuery.get("room")
        filterQuery.search = [search];
        if(location?.state?.roomName) {
          filterQuery?.search.push(location?.state?.roomName)
        }
        if(room) {
          filterQuery.room = [room];
        }
      }
      if(selectedSortValue !== 0) {
        if(selectedSortValue === 1 || selectedSortValue === 2) {
          filterQuery.order_price = sortList?.[selectedSortValue]?.value
        }
        else if(selectedSortValue === 3 || selectedSortValue === 4) {
          filterQuery.brand_name = sortList?.[selectedSortValue]?.value
        }
        else if(selectedSortValue === 5) {
          filterQuery.promotion = sortList?.[selectedSortValue]?.value
        }
      }
      setCurrentPage(page || 1)
      getAllProducts(page || 1, filterQuery)
      isInit && setAllowUpdate(true)
    }
  }

  const handlePagination = (current) => {
    let filterQuery = {};
    for(const option in filterOptions) {
      if(filterOptions[option].length > 0) {
        filterQuery[convertFilterCategoryNames(option)] = filterOptions[option];
      }
    }
    filterData(null, null, current)
    // setCurrentPage(current);
    // getAllProducts(current, filterQuery);
  };

  const handleFilterChange = (filter) => {
    if ((selectedFilter === 'all' && filter === 'all') || (selectedFilter === 'sort' && filter === 'sort')) {
      setSelectedFilter('');
    } else {
      setSelectedFilter(filter);
    }
  }

  const validateFilterOptions = (filterOptionsNew) => {
    // check to see if there are any items in the arrays
    let itemExists = false;
    if(filterOptionsNew) {
      for(const key in filterOptionsNew) {
        if(filterOptionsNew && filterOptionsNew[key]) {
          itemExists = true;
        }
      }
    }
    else {
      for(const key in filterOptions) {
        if(filterOptions && filterOptions[key]) {
          itemExists = true;
        }
      }
    }
    return itemExists;
  }

  const convertFilterCategoryNames = value => {
    if(value === "Ruimtes") {
      return "room";
    }
    else if(value === "Woonstijl") {
      return "style";
    }
    else if(value === "Materiaal") {
      return "material";
    }
    else if(value === "Winkel") {
      return "location";
    }
    else if(value === "Merken") {
      return "brand";
    }
    else if(value === "price") {
      return "price";
    }
    else if(value === "search") {
      return "search";
    }
    else {
      return "";
    }
  }

  const getPagesCount = () => {
    let pageCount = 0;
    let totalProducts = product.all_products?.total;
    let pages = Math.ceil(totalProducts / 20);

    if (products?.length && currentPage < pages ) {
      pageCount = currentPage * 20;
    } else if (products?.length && currentPage >= pages) {
      pageCount = totalProducts;
    }

    return pageCount;
  };

  const handleSortItemClick = index => {
    setSelectedSortValue(index);
    setSortFilterState(false)
  };

  return (
    <div id="inventory-page">
      <StickyButton />

      <Grid container className="top-section pb-[15px]">
        <Container maxWidth="lg">
          <Grid item md={12}>
            <BreadcrumbsItem options={options} />
          </Grid>

          <Grid item md={12}>
            <h3 className="top-text-heading">{pageTitle}</h3>
          </Grid>
        </Container>
      </Grid>
      <Container maxWidth="lg">
        <Grid container className="pt-[17px]">
          {(width < breakpoint) ? (
              <div className="w-full">

                <Grid container className="filters-mobile">
                  <Grid
                      item
                      md={6}
                      onClick={() => handleFilterChange('all')}
                      className={`filter-button1 ${selectedFilter === 'all' && 'all-selected'}`}
                  >
                    Apply Filters
                  </Grid>

                  <Grid
                      item
                      md={6}
                      onClick={() => handleFilterChange('sort')}
                      className={`filter-button2 ${selectedFilter === 'sort' && 'sort-selected'}`}
                  >
                    Sort By
                  </Grid>
                </Grid>

                <Grid container>
                  {selectedFilter === 'all' && (
                    <Grid item md={12} className="p-[10px]" style={{ border: '1px solid #c98468'}}>
                      <Filter filterOptions = {filterOptions} setFilterOptions = {setFilterOptions} />
                    </Grid>
                  )}
                  {selectedFilter === 'sort' && (
                      <Grid item md={12} className="m-[10px]" style={{ border: '1px solid #c98468', width: '100%' }}>
                        <div className="w-full bg-white box-border p-[10px]">
                          {sortList && sortList.map((sortItem, sortIndex) => (
                            <p className="font-montserrat font-light text-[13px] text-[#27292C] tracking-[0.7px] cursor-pointer leading-[25px]" onClick = {() => handleSortItemClick(sortIndex)}>
                              {sortItem?.name}
                            </p>
                          ))}
                        </div>
                      </Grid>
                  )}
                </Grid>

                <Grid container className="inventory">
                  {products?.map((product, index) => (
                      <Grid item md={4} className="inventory-item w-full" key={index}>
                        <InventoryCard product={product} currentPage={currentPage} />
                      </Grid>
                  ))}
                </Grid>

                <div className="flex justify-center sm:justify-start">
                  <Pagination
                      total={pages}
                      current={currentPage}
                      pagination={(crPage) => handlePagination(crPage)}
                  />
                </div>

                <div className="right-total-items text-center sm:text-right mt-[10px] mb-[30px]">
                  {`${getPagesCount()} van ${product?.all_products?.total} producten`}
                </div>
              </div>
          ) : (
              <>
                <Grid item md={3}>
                  <Filter filterOptions = {filterOptions} setFilterOptions = {setFilterOptions} />
                </Grid>
                <Grid item md={9}>
                  <div className="inventory-list-top-filter">
                    <Grid container className="pb-[17px]">
                      <Grid item md={7}>
                        <div className="w-[210px] flex flex-col relative bg-white z-50">
                          <div
                              className="w-full h-[25px] flex justify-between items-center cursor-pointer box-border px-[10px]"
                              onClick={() => setSortFilterState(!sortFilterState)}
                          >
                            <p className="font-montserrat font-regular text-[14px] text-[#27292C] tracking-[0.7px]">
                              {sortList[selectedSortValue]?.name}
                            </p>
                            <div
                                className="animated"
                                style={{
                                  transform: sortFilterState
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                }}
                            >
                              <IoIosArrowDown color="#C98468" size="1.25rem" />
                            </div>
                          </div>
                          <div
                              className="w-full flex flex-col inventory-dropdown-container absolute top-[0px] left-[0px] bg-white animated overflow-hidden"
                              style={{ display: sortFilterState ? "flex" : "none" }}
                          >
                            <div
                                className="w-full h-[25px] flex justify-between items-center cursor-pointer box-border px-[10px]"
                                onClick={() => setSortFilterState(!sortFilterState)}
                            >
                              <p className="font-montserrat font-regular text-[14px] text-[#27292C] tracking-[0.7px]">
                                {sortList[selectedSortValue]?.name}
                              </p>
                              <div
                                  className="animated"
                                  style={{
                                    transform: sortFilterState
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                  }}
                              >
                                <IoIosArrowDown color="#C98468" size="1.25rem" />
                              </div>
                            </div>
                            <div className="flex flex-col w-full bg-white box-border p-[10px]">
                              {sortList && sortList.map((sortItem, sortIndex) => (
                                <p className="font-montserrat font-light text-[13px] text-[#27292C] tracking-[0.7px] cursor-pointer leading-[25px]" onClick = {() => handleSortItemClick(sortIndex)}>
                                  {sortItem?.name}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} className="sort-right">
                        <Pagination
                            total={pages}
                            current={currentPage}
                            pagination={(crPage) => handlePagination(crPage)}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="right-total-items text-right mt-[10px] mb-[30px]">
                    {`${getPagesCount()} van ${product?.all_products?.total} producten`}
                  </div>

                  <Grid container className="inventory">
                    {products?.length ? (
                      products?.map((product, index) => (
                          <Grid item md={4} className="inventory-item w-full" key={index}>
                            <InventoryCard product={product} currentPage={currentPage} />
                          </Grid>
                      ))
                    ) : (
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            color: '#8d908b',
                          }}
                        >Geen gerelateerde producten gevonden.</div>
                  )}
                  </Grid>
                </Grid>
              </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ product, loader }) => ({ product, loader });
export default connect(mapStateToProps, { getAllProducts })(InventoryPage);
