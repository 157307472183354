import React, {useState} from 'react';
import { Grid, Container, Divider } from "@mui/material";
import RoundedBtn from '../rounded_button/rounded_button';

// image imports here
import closeIcon from '../../images/icons/close-circle.svg';
import group from '../../images/icons/Group.svg';

import './cookie_alert.scss';
import CheckBox from "../check_box/check_box";
import {Link} from "react-router-dom";

const CookieAlert = ({
    cancelCookie,
    setCancelCookie,
    setCookie,
    cookie,
    alert,
}) => {
    const [settingView, toggleSettingView] = useState(false);
    const [isAgree, toggleIsAgree] = useState(false);

    return (
        ((!cancelCookie && alert?.show) || !cookie) && (
            <Grid container className='cookie-container'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container>
                        {!cancelCookie && alert?.show && (
                            <>
                                <div className='cookie-main-title'>
                                  <span className='fs-26'>
                                      {alert.title}
                                  </span>
                                    <img
                                        src={closeIcon} alt='clock'
                                        className='cookie-close-image'
                                        onClick={()=> setCancelCookie(true)}
                                    />
                                </div>
                                <div className='cookie-section'>
                                    <img src={alert?.logo} alt='clock' className='cookie-clock-image'/>
                                    <span className='cookie-para-padding' dangerouslySetInnerHTML={{ __html: alert.body }} />
                                </div>
                                {!cookie && (<Divider style={{ background: 'white' }} />)}
                            </>
                        )}

                        {!cookie && !settingView && (
                            <>
                                <div className='text-center pt-2'>
                                <span className='fs-26'>
                                    Cookies
                                </span>
                                </div>
                                <div className='cookie-section'>
                                    <img src={group} alt='group' className='cookie-cookie-image'/>
                                    <span className='cookie-para-padding'>
                                    Welkom op de website van Top Interieur. Om uw navigatie te verbeteren en u een
                                    optimale ervaring te bieden, maakt deze site gebruik van cookies. Klik op "accepteren"
                                    om cookies te accepteren en direct naar de site te gaan, of maak uw eigen keuze en
                                    bevestig uw keuze. Voor meer informatie over de soorten cookies die worden gebruikt, <Link to = "/cookies" className='cookie-link'>klik hier</Link>
                                </span>
                                </div>
                                <div className='cookie-btn-container'>
                                    <RoundedBtn  title={'Meer informatie'} btnClass={'cookie-btn-1 mr-2'} handler={() => toggleSettingView(!settingView)} />
                                    <RoundedBtn  title={'Alles accepteren'} btnClass={'button-2'} handler={setCookie} disabled={isAgree} />
                                </div>
                            </>
                        )}

                        {!cookie && settingView && (
                            <>
                                <div className='text-center pt-2'>
                                    <span className='fs-26'>
                                        Cookies
                                    </span>
                                </div>
                                <div className='cookie-section'>
                                    <img src={group} alt='group' className='cookie-cookie-image'/>

                                    <div className="pl-[25px]">
                                        <h4 style={{ fontSize: 20, fontWeight: 600 }}>Consensus</h4>
                                        <div style={{ fontWeight: 400 }} className='pb-[30px]'>
                                            Door op 'Alles accepteren' te klikken gaat u akkoord met de cookies.
                                            De website Top Interieur gebruikt alleen absoluut noodzakelijke
                                            cookies om de website te laten functioneren.
                                            Voor meer informatien <Link  className="item-text-footer cookie-link" to="/cookies">klik hier</Link> of neem contact met ons op via e-mail info.
                                            izegem@topinterieur.be of info.massenhoven@topinterieur.be.
                                        </div>

                                        <Divider style={{ background: 'white' }} />

                                        <h4 style={{ fontSize: 20, fontWeight: 600 }} className="pt-[25px]">Details</h4>
                                        <h6 style={{ fontWeight: 600 }}>Noodzakelijk</h6>
                                        <div style={{ fontWeight: 400 }} className='pb-[30px]'>
                                            We maken gebruik van universal cookies.
                                            Deze worden gebruikt voor het opslagen,
                                            instellen en krijgen van cookies.

                                            <div className="pt-[5px]">
                                                <CheckBox initialValue={isAgree} onClick={() => toggleIsAgree(!isAgree)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='cookie-btn-container'>
                                    <RoundedBtn  title={'Terug'} btnClass={'cookie-btn-1 mr-2'} handler={() => toggleSettingView(!settingView)} />
                                    <RoundedBtn  title={'Alles accepteren'} btnClass={'button-2'} handler={setCookie} disabled={isAgree} />
                                </div>
                            </>
                        )}
                    </Container>
                </Grid>
            </Grid>
        )
    )
}

export default CookieAlert;
