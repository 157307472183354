import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkURLValidity } from "../../helpers/imageURLValidity";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import {getFullURL, getFullURLMain} from "../../helpers/getfullURL";

// image imports here
import imageHeart from "../../assets/icons/heart.svg";
import LogoTop from "../../images/icons/logo_top.png";

import "./inventory_card.scss";
import axios from "axios";
import {
  BASE_URL,
  createWishlist,
  updateWishlist,
  deleteWishList
} from "../../redux/constants/ApiEndPoints";
import StickyButton from "../maakeenafspraak_sticky_button/sticky_button";

const InventoryCard = ({ product, currentPage, isFavourite, wishlist, productBrandName, onDelete }) => {
  const [isInzegem, setIsInzegem] = useState();
  const [isMassenhoven, setIsMassenhoven] = useState();
  const [urlImage, setUrlImage] = useState("");
  const [wishlistObject, setWishlistObject] = useState(null);

  const imageBaseUrl = "https://tp-storage.s3.fr-par.scw.cloud/import";
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const navigate = useNavigate();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    const fetchData = () => {
      if (product.id === 171) {
        console.log(getFullURL(product?.main_image));
      }
  
      setUrlImage(getFullURL(product?.main_image));
  
      if (product) {
        product.product_locations?.forEach((location) => {
          if (location?.location?.name === "TMI") setIsInzegem(true);
          if (location?.location?.name === "TIM") setIsMassenhoven(true);
        }); 
      }
    };
  
    fetchData();
  }, [product]);

  const handleFavoriteClick = () => {
    console.log('wishlist', wishlist);
    if (product && userData?.id) {
      if (wishlistObject) {
        console.log('first if', wishlistObject);
        axios
          .put(BASE_URL + updateWishlist + wishlistObject?.id, {
            is_favourite: !wishlistObject?.is_favourite,
          }, {headers})
          .then((response) => {
            setWishlistObject(response.data.data);
          })
          .catch((error) => console.log(error));
      } else {
        console.log('else first');
        let doesWishlistExist = false;
        let wishlistItem = {};
        if(product?.wishlist) {
          console.log('second if product?.wishlist');
          // if the product contains the wishlist data, then go through this condition (inventory page)
          doesWishlistExist =
          product?.wishlist?.length > 0 &&
          product?.wishlist[0]?.user_id === userData?.id
            ? true
            : false;
            wishlistItem = product?.wishlist[0]
        }
        else {
          console.log('second else wishlist.forEach');
          // if product does not contain wishlist data then manually search through wishlist object for the product (favorites page)
          wishlist?.forEach(item => {
            if(item?.product_id === product?.id && item?.is_favourite) {
              doesWishlistExist = true;
              wishlistItem = {...item};
            }
          })
        }
        if (doesWishlistExist) {
          console.log('third if doesWishlistExist');
          // if product exist using either of the two methods above then simply then remove it from the data (it means it has already been favorited)
          axios
            .delete(BASE_URL + deleteWishList + wishlistItem?.id, {headers}).then((response) => {
              setWishlistObject([]);
              onDelete();
            }).catch((error) => console.log(error));
        } else {
          console.log('third else');
          // if product does not exist using either of the two moethods above then add it to the list (it means it doesn't exist)
          axios
            .post(BASE_URL + createWishlist, {
              user_id: userData?.id,
              product_id: product?.id,
              type: 1
            }, {headers})
            .then((response) => {
              setWishlistObject(response.data.data);
            })
            .catch((error) => console.log(error));
        }
      }
    }
    else {
      navigate("/info")
    }
  };

  const displayFavoriteIcon = () => {
    if (wishlistObject) {
      return wishlistObject?.is_favourite;
    }
    else if(isFavourite){
      return isFavourite;
    }
    else {
      if (product?.wishlist?.length > 0 && product?.wishlist[0]?.user_id === userData?.id) {
        return product?.wishlist[0]?.is_favourite;
      } else {
        return false;
      }
    }
  };

  if (product) {
    let logo = productBrandName ? productBrandName : product?.brand;
    return (
        <>
          <StickyButton />
          <div id="inventory-card">
            <Link
                to={
                  currentPage
                    ? `/product-details/product/${product.id}`
                    : "/favorites"
                }
                className="link-style-class"
              >
                <div className="inventory-item w-full pl-[15px] pr-[15px] h-full">
                  <div className="item-hover">
                    <div className="image-section">
                      <div className="flex">
                        <div className="w-1/5 pl-[5px]">
                          {product?.product_images?.map((image) => (
                            <div className="pt-[5px] pb-[5px]">
                              <img alt="" className = "h-[53px] object-contain object-center" src = {getFullURL(image?.url)} />
                            </div>
                          ))}
                        </div>
                        <div className="w-4/5 pt-[5px] pb-[5px] pl-[15px] pr-[5px]">
                          <img alt=""className = "h-[185px] object-contain object-center" src = {urlImage}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item flex justify-center w-full">
                    <img style={{backgroundColor: 'rgba(208, 204, 198, 0.6)'}} width={400} alt="" className = "h-[140px] object-contain object-center" src = {urlImage} />
                  </div>
                </div>
              </Link>
              <div className="pl-[15px] pr-[15px] w-full">
                <div className="description-section w-full mt-[30px]">
                  <div>
                    <Link
                      to={
                        currentPage
                          ? `/product-details/product/${product.id}`
                          : "/favorites"
                      }
                      className="link-style-class"
                    >
                      {logo?.logo ? (
                        <img
                          src={
                            checkURLValidity(getFullURL(logo?.logo))
                              ? logo?.logo
                              : LogoTop
                          }
                          style={{ width: "60%", height: '30px' }}
                          alt="logo"
                        />
                      ) : logo?.name ? (
                        <span className="second_name_text">
                          {logo?.name}
                        </span>
                      ) : (
                        logo === null ?
                        <img style={{ width: "60%" }} src = {LogoTop} alt = "logo" />
                        :
                        <span className="second_name_text">
                        {logo}
                        </span>                          
                      )}
                    </Link>
                    <div style={{ width: "100%" }}>
                      <span
                        onClick={() => handleFavoriteClick()}
                        style={{
                          float: "right",
                          cursor: "pointer",
                          marginTop: -25
                        }}
                      >
                        {displayFavoriteIcon() ? (
                          <IoMdHeart size="1.5em" color="#C98468" />
                        ) : (
                          <IoMdHeartEmpty size="1.5em" color="#C98468" />
                        )}
                      </span>
                    </div>
                  </div>
                  <Link
                    to={
                      currentPage
                        ? `/product-details/product/${product.id}`
                        : "/favorites"
                    }
                    className="link-style-class"
                  >
                    {product.commercial_description && (
                      <div
                        className="description-text font-[Montserrat] text-[13px] text-[#404040]"
                        dangerouslySetInnerHTML={{
                          __html: product.commercial_description.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          ),
                        }}
                      />
                    )}
                      <div className = "flex flex-col w-full mt-[10px]">
                      {product?.price && (
                        <p className = "font-[Montserrat] font-bold text-[16px] text-[#c98468]">Incl. BTW</p>
                      )}
                      <div className="font-normal text-base flex">
                        <div className="flex">
                          {product.price && !product.fixed && "Vanaf"}
                          {(product?.promotion_price) ? (
                            <span className = "cut-price mr-[10px]">€ {product.price}</span>
                          ) : (
                            <>
                            </>
                          )}
                          {(product.price || product?.promotion_price) && (
                            <span className="price">€ {product?.promotion_price || product.price}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
          </div>
        </>
    );
  } else {
    return null;
  }
};

export default InventoryCard;
