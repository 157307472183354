import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { Grid, Container } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import './index.scss'
import index from '../project_category_details';

const Tabs = ({selectedTab, isPageLogin = false, showHeader}) => {
    const [width, setWidth] = useState(window.innerWidth);
    
    const navigate = useNavigate();
    const borderTabInActive = 'border-b-[#C98468] border-b-[1px] text-[#C98468]';
    const borderTabActive = 'border-x-[#C98468] border-x-[1px] border-t-[1px] border-t-[#C98468] rounded-t-[10px] font-bold text-[#404040]'
    const links = ['/orders','/cart','/favorites', '/info']
    const breakpoint = 1024;
    const COLOR_BLACK = '#404040';
    const COLOR_ORANGERED = '#C98468';
    const location = useLocation();
    const mobileBarRef = useRef(0);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    useLayoutEffect(() => {
      const loadRef = () => {
        if (mobileBarRef.current) {
          mobileBarRef.current.scroll({ top: 0, left: 270 * selectedTab, behavior: "smooth" });
        } else {
          setTimeout(loadRef, 100);
        }
      };
  
      setTimeout(loadRef, 500);
    }, [selectedTab]);  

    const Header = () => {
      return(
          <div className='w-full text-[35px] py-[35px] font-dmserif' style = {{display: showHeader ? 'block' : 'none'}}>
              Welkom {showHeader}
          </div>
      );
  }

    const PersonSVG = ({color= COLOR_ORANGERED}) => {

        return (
            <div className='h-[20px] w-[20px] m-auto block text-center'>
                <svg fill={color} x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xmlSpace="preserve">
                <g><path d="M649.6,584.8c94.8-52.7,159-153.8,159-270C808.6,144.4,670.5,6.2,500,6.2c-170.5,0-308.6,138.2-308.6,308.6c0,116.2,64.2,217.3,159,270C169.7,642.2,34.1,800.7,10,993.8h62.3c30-209.4,210-370.4,427.7-370.4c217.7,0,397.8,161,427.7,370.4H990C965.9,800.7,830.3,642.2,649.6,584.8z M253.1,314.8c0-136.4,110.5-246.9,246.9-246.9c136.4,0,246.9,110.5,246.9,246.9S636.4,561.7,500,561.7S253.1,451.2,253.1,314.8z"/></g>
                </svg>
            </div>
        )
    }

    const selectColor = (tabNo) => {
        if (tabNo === selectedTab) {
            return {
                color: COLOR_BLACK, 
                borderTop: `solid 1px #C98468`,
                borderRight: `solid 1px #C98468`,
                borderLeft: `solid 1px #C98468`,
                borderTopLeftRadius:'10px',
                borderTopRightRadius:'10px'

            };
            
        }

        return {
            color: COLOR_ORANGERED,
            borderBottom: `solid 1px #C98468`,
        }
    }

    return (
        <>
            <Grid container justifyContent= "center" alignItems = "center" className='bg-[#fafafa]'>
                <Container maxWidth = "lg">
                    {!isPageLogin && <Header />}
                </Container>
            </Grid>
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    {width > breakpoint ?
                    <div className='products_tabs mt-[40px] text-center py-[10px] cursor-pointer mb-[50px] text-[20px]'>
                        {['Mijn orders', 'Mijn winkelwagen', 'Mijn favoriete artikelen', 'Mijn persoonlijke info'].map((each, index)=> {
                            return (
                                <div
                                    className={`${selectedTab === index ? borderTabActive : borderTabInActive} py-[10px]`}
                                    onClick={() => navigate(links[index], {state: { from: location.pathname }})}
                                >
                                    {each}
                                </div>
                            )
                        })}
                    </div> : (
                        <div ref = {mobileBarRef} className='w-screen flex overflow-x-scroll h-[60px] mt-[40px] text-center cursor-pointer mb-[50px] text-[20px]'>
                            {[
                                'Mijn orders', 
                                'Mijn winkelwagen',
                                'Mijn favoriete artikelen',
                                'Mijn persoonlijke info'
                            ].map((each, index)=> {
                                return (
                                    // <Link to={links[index]} style={{...selectColor(index), textDecoration: 'none', textAlign:'center'}}>
                                    //     {index !== 0 ? 
                                    //         <div className='pt-[10px]' >{each}</div>:
                                    //         <div className='py-[10px]'>{each}</div>
                                    //     }
                                    // </Link>
                                    <div className = "w-[270px] h-full bg-white flex shrink-0 justify-center items-center border-b-[1px] border-b-[#acacac] border-r-[1px] border-r-[#acacac]" onClick = {() => navigate(links[index])}>
                                        {selectedTab === index ? (
                                            <p className = "font-[Montserrat] text-[#404040] font-bold text-[18px]">{each}</p>
                                        ) : (
                                            <p className = "font-[Montserrat] text-[#C98468] font-regular text-[18px]">{each}</p>
                                        )}
                                    </div>
                                )
                            })}
                        </div>)
                    }
                </Container>
            </Grid>
        </>
    )
}

export default Tabs;
