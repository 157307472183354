import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { IoIosArrowDown, IoIosSearch, IoMdAdd, IoMdRemove } from "react-icons/io";
import axios from "axios"
import { debounce } from "lodash";

import CheckBox from "../../../components/check_box/check_box";
import PriceSlider from "./price_slider";
import { BASE_URL, category, getFilterLocations, getFilterBrands } from "../../../redux/constants/ApiEndPoints";

import "./filter.scss";

function SubFilter(props) {
  const [filterState, setFilterState] = useState(null);
  const [filterOpenState, setFilterOpenState] = useState(false);

  const { filterData, filterCategory, handleFilterChange, handleMerkenFilterChange } = props;

  useEffect(() => {
    if (filterData) {
      const filterDataCopy = JSON.parse(JSON.stringify(filterData));
      setFilterState(filterDataCopy);

      // // if there is brand search param, match id to enable checkbox
      // const query = window.location.search;
      // const parsedQuery = new URLSearchParams(query);
      // const brand = parsedQuery.get("brand");
      // if(brand && filterData?.id == brand) {
      //   setFilterOpenState(true)
      // }

      // // after search, is updated we want to check the boxes from before
      // let match = false;
      // filterOptions.Merken.forEach(brand => {
      //   if(brand === filterData?.id) {
      //     match = true;
      //   }
      // })
      // setFilterOpenState(match)
    }
  }, [filterData])

  const handleCheckboxClick = () => {
    if(filterCategory === "Product categorie") {
      handleFilterChange(!filterOpenState, filterData?.name, "search")
    }
    else {
      handleFilterChange(!filterOpenState, filterData?.id, filterCategory)
    }
    setFilterOpenState(!filterOpenState);
  };

  const showFullLocation = (value) => {
    if(value === "TMI") {
      return "Izegem";
    }
    else if(value === "TIM") {
      return "Massenhoven";
    }
    else {
      return value;
    }
  }

  return (
    <div
      key={filterState?.id}
      className={`flex ${filterState?.isColor ? "flex-row" : "flex-col"}`}
    >
      {filterState?.isColor ? (
        <div
          className="h-[28px] w-[28px] rounded-[14px] cursor-pointer mr-[13px] mb-[13px]"
          style={{
            backgroundColor: filterState?.value || "#FFF",
            border: filterState?.hasBorder ? "solid 1px #D0CCC6" : "none",
          }}
        />
      ) : filterCategory === "Merken" ? (
          <div className = "flex items-center mb-[11px]">
            <div className = "cursor-pointer" onClick = {() => handleMerkenFilterChange(filterData)}>
              <IoMdAdd size = "1.25em" color = "#C98468" />
            </div>
            <p className="font-montserrat font-light text-[14px] text-[#27292C] ml-[16px]">
              {filterState?.second_name}
            </p>
          </div>
      ) : (
        <>
          <div className="flex items-center mb-[11px]">
            <CheckBox
              initialValue={filterOpenState}
              onClick={handleCheckboxClick}
            />
            <p
              className={`font-montserrat font-${
                filterState?.isMenu ? "semibold" : "light"
              } text-${
                filterState?.isMenu ? "[16px]" : "[14px]"
              } text-[#27292C] ml-[16px]`}
            >
              {showFullLocation(filterState?.title || filterState?.name || "")}
            </p>
          </div>
          <div
            className="flex flex-col w-full ml-[25px] animated overflow-x-hidden overflow-y-auto"
            style={{
              opacity: filterOpenState ? 1 : 0,
              maxHeight: filterOpenState ? "1000px" : "0px",
            }}
          >
            {filterState?.isMenu &&
              filterState?.menuData &&
              filterState?.menuData?.length > 0 &&
              filterState?.menuData.map((subFilterOption) => (
                <div
                  key={subFilterOption.id}
                  className="flex items-center mb-[11px]"
                >
                  {subFilterOption?.isColor ? (
                    <>
                      <div
                        className="h-[28px] w-[28px] rounded-[14px] cursor-pointer mr-[13px] mb-[13px]"
                        style={{
                          backgroundColor: subFilterOption?.value || "#FFF",
                          border: subFilterOption?.hasBorder
                            ? "solid 1px #D0CCC6"
                            : "none",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <CheckBox />
                      <p className="font-montserrat font-light text-[14px] text-[#27292C] ml-[16px]">
                        {subFilterOption?.title}
                      </p>
                    </>
                  )}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

function FilterCategory(props) {
  const [filterState, setFilterState] = useState([]);

  const { filterData, handleFilterChange, handlePriceFilterChange, handleMerkenSearchUpdate, filterOptions, handleMerkenFilterChange, handleMerkenRemoveFilter, onUnselectAll } = props;
  const categorySearchRef = useRef(0);
  
  useEffect(() => {
    if (filterData && filterData.length > 0) {
      const filterDataCopy = JSON.parse(JSON.stringify(filterData));
      setFilterState(filterDataCopy);
    }
  }, [filterData]);

  const handleCategoryChange = (index, type) => {
    if(!type) {
      if (filterState[index].isOpen) {
        const filterStateCopy = JSON.parse(JSON.stringify(filterState));
        filterStateCopy[index].isOpen = false;
        setFilterState(filterStateCopy);
      } else {
        const filterStateCopy = JSON.parse(JSON.stringify(filterState));
        filterStateCopy[index].isOpen = true;
        setFilterState(filterStateCopy);
      }
    }
  };

  const debounceSearchChange = debounce(value => {
    handleMerkenSearchUpdate(value)
  }, 500)

  const onCategorySearchChange = event => {
    debounceSearchChange(event.target.value)
  }

  return (
    <>
    {/* {filterCategoryState && filterCategoryState?.length > 0 && filterCategoryState.map((category, categoryIndex) => (
      <div key={category.id} className="flex flex-col w-full">
        <div
          className={`w-full box-border ${
            dataIndex !== 0 ? "py-[17px]" : "pb-[17px]"
          } flex justify-between items-center cursor-pointer`}
          onClick={() => handleCategoryChange(categoryIndex)}
        >
          <p className="font-montserrat font-semibold text-[16px]">
            {data?.title}
          </p>
          <div
            className="animated"
            style={{
              transform: data?.isOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <IoIosArrowDown color="#C98468" size="1.25rem" />
          </div>
        </div>
        <div
          className="w-full flex flex-col overflow-hidden animated overflow-x-hidden overflow-y-auto"
          style={{
            opacity: data?.isOpen ? 1 : 0,
            maxHeight: data?.isOpen ? "1000px" : "0px",
          }}
        >
          {data?.hasSearch && (
            <div className="flex flex-col w-full mb-[15px]">
              <div className="w-[90%] h-[35px] rounded-[20px] bg-[#F7F6F5] flex items-center box-border px-[10px] mb-[7px]">
                <input
                  className="font-montserrat font-regular text-[14px] bg-transparent w-full outline-none"
                  placeholder="Zoek naar merk"
                />
                <IoIosSearch color="#C98468" size="1.25rem" />
              </div>
              <p className="font-montserrat font-light text-[14px] text-[#C98468] cursor-pointer underline">
                Unselect all
              </p>
            </div>
          )}
          <div
            className={`w-full flex flex-wrap ${
              data?.isColor ? "flex-row" : "flex-col"
            }`}
          >
            {data?.data &&
              data?.data?.length > 0 &&
              data?.data.map((filterOption) => (
                <SubFilter filterData={filterOption} filterCategory = {data?.title} handleFilterChange = {handleFilterChange} />
              ))}
          </div>
        </div>
        <div className="h-[1px] w-full bg-[#D0CCC671]" />
      </div>
    ))} */}
    {/* standard filter menus */}
      {filterState &&
        filterState?.length > 0 &&
        filterState.map((data, dataIndex) => {
          return data?.display && (
            <div key={data.id} className="flex flex-col w-full">
              <div
                className={`w-full box-border ${
                  dataIndex !== 0 ? "py-[17px]" : "pb-[17px]"
                } flex justify-between items-center cursor-pointer`}
                onClick={() => handleCategoryChange(dataIndex)}
              >
                <p className="font-montserrat font-semibold text-[16px]">
                  {data?.title}
                </p>
                <div
                  className="animated"
                  style={{
                    transform: data?.isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <IoIosArrowDown color="#C98468" size="1.25rem" />
                </div>
              </div>
              {data?.hasSearch && (
                <div className = "w-full flex-col mb-[15px]">
                  {filterOptions?.Merken?.map((selectedBrand, selectedBrandIndex) => (
                    <div className = "flex items-center mb-[11px] ">
                      <div className = "cursor-pointer">
                        <IoMdRemove size = "1.25em" color = "#C98468" onClick = {() => handleMerkenRemoveFilter(selectedBrandIndex)} />
                      </div>
                      <p className="font-montserrat font-light text-[14px] text-[#27292C] ml-[16px]">{selectedBrand?.name}</p>
                    </div>
                  ))}
                </div>
              )}
              <div
                className="w-full flex flex-col overflow-hidden animated overflow-x-hidden overflow-y-auto"
                style={{
                  opacity: data?.isOpen ? 1 : 0,
                  maxHeight: data?.isOpen ? "1000px" : "0px",
                }}
              >
                {data?.hasSearch && (
                  <div className="flex flex-col w-full mb-[15px]">
                    <div className="w-[90%] h-[35px] rounded-[20px] bg-[#F7F6F5] flex items-center box-border px-[10px] mb-[7px]">
                      <input
                        className="font-montserrat font-regular text-[14px] bg-transparent w-full outline-none"
                        placeholder="Zoek naar merk"
                        ref = {categorySearchRef}
                        onChange = {onCategorySearchChange}
                      />
                      <IoIosSearch color="#C98468" size="1.25rem" />
                    </div>
                    {filterOptions.Merken?.length > 0 ? (
                      <p className="font-montserrat font-light text-[14px] text-[#C98468] cursor-pointer underline" onClick = {() => onUnselectAll(data?.title)}>
                        Unselect all
                      </p>
                    ) : (
                      <p className="font-montserrat font-light text-[14px] text-[#C98468] cursor-pointer underline" onClick = {() => onUnselectAll(data?.title, data?.data)}>
                        Select all
                      </p>
                    )}
                  </div>
                )}
                <div
                  className={`w-full flex flex-wrap ${
                    data?.isColor ? "flex-row" : "flex-col"
                  }`}
                >
                  {data?.data &&
                    data?.data?.length > 0 &&
                    data?.data.map((filterOption) => (
                      <SubFilter filterData={filterOption} filterCategory = {data?.title} handleFilterChange = {handleFilterChange} filterOptions = {filterOptions} handleMerkenFilterChange = {handleMerkenFilterChange} />
                    ))}
                </div>
              </div>
              <div className="h-[1px] w-full bg-[#D0CCC671]" />
            </div>
          )
        })}
      <PriceSlider handleFilterChange = {handlePriceFilterChange} />
    </>
  );
}

export default function Filter(props) {
  const [filterData, setFilterData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);

  const { filterOptions, setFilterOptions } = props;

  useEffect(() => {
    getFilterData();
  }, [])

  const getFilterData = () => {
    let newFilterData = [];
    axios.get(BASE_URL + getFilterLocations).then(locationDataResponse => {
      const locationData = {
        title: "Winkel",
        isOpen: false,
        hasSearch: false,
        hasColor: false,
        display: true,
        data: locationDataResponse.data.data
      }
      newFilterData.push(locationData)
    }).then(() => {
      axios.get(BASE_URL + getFilterBrands).then(brandDataResponse => {
        const brandData = {
          title: "Merken",
          isOpen: false,
          hasSearch: true,
          hasColor: false,
          display: true,
          data: brandDataResponse.data.data
        }
        // if we have brand id in our search param, we need to enable the respective checkbox
        newFilterData.push(brandData)
        setFilterData(newFilterData)
      }).catch(error => console.log(error))
    }).catch(error => console.log(error))
  }

  useEffect(() => {
    axios.get(BASE_URL + category + "?show=true").then(response => {
      setCategoryData(response?.data?.data || [])
  }).catch(error => console.log(error))
  }, [])

  const handleFilterChange = (value, id, category) => {
    // create hard copy of filter state
    const filterOptionsCopy = JSON.parse(JSON.stringify(filterOptions));
    let selectedSubFilter = filterOptionsCopy[category]; 
    // if value is true then that means we need to add it to the list, otherwise we remove it
    if(value) {
      // check if the value already exists 
      const exists = selectedSubFilter.find(subfilter => subfilter === id)
      if(!exists) {
        selectedSubFilter.push(id)
        let newFilterCategory = {};
        newFilterCategory[`${category}`] = selectedSubFilter;
        setFilterOptions({...filterOptions, ...newFilterCategory})
      }
    }
    else {
      // remove the value here
      // check if the value already exists 
      const exists = selectedSubFilter.find(subfilter => subfilter === id)
      if(exists) {
        const indexOfItem = selectedSubFilter.indexOf(id)
        selectedSubFilter.splice(indexOfItem, 1)
        let newFilterCategory = {};
        newFilterCategory[`${category}`] = selectedSubFilter;
        setFilterOptions({...filterOptions, ...newFilterCategory})
      }
    }
  }

  const handleMerkenFilterChange = (data) => {
    const filterOptionsCopy = JSON.parse(JSON.stringify(filterOptions))
    let selectedSubFilter = filterOptionsCopy.Merken;
    // check if value already exists in the merken filter pool
    const exists = selectedSubFilter.find(selectedBrand => selectedBrand?.id === data?.id);
    if(exists) {
      // brand already exists in the filtered list, so we do nothing
    }
    else {
      // brand doesn't exist in the filtered list, so we add it
      filterOptionsCopy.Merken.push({
        id: data?.id,
        name: data?.second_name
      })
      setFilterOptions(filterOptionsCopy);
    }
  }

  const handlePriceFilterChange = (minValue, maxValue) => {
    if(filterOptions) {
      const filterOptionsCopy = JSON.parse(JSON.stringify(filterOptions))
      let priceFilter = filterOptionsCopy['price'];
      priceFilter[0] = minValue;
      priceFilter[1] = maxValue;
      setFilterOptions({...filterOptions, price: priceFilter})
    }
  }

  const handleMerkenSearchUpdate = (value) => {
    axios.get(BASE_URL + getFilterBrands, {
      params: {
        search: value
      }
    }).then(brandDataResponse => {
      const brandData = {
        title: "Merken",
        isOpen: true,
        hasSearch: true,
        hasColor: false,
        display: true,
        data: brandDataResponse.data.data
      }
      // we find the index of merken object and replace it 
      let indexOfMerken = null;
      filterData?.forEach((filterItem, filterIndex) => {
        if(filterItem?.title === "Merken") {
          indexOfMerken = filterIndex
        }
      })
      let filterDataCopy = [...filterData];
      filterDataCopy[indexOfMerken] = brandData;
      setFilterData(filterDataCopy)
      setFilterOptions({...filterOptions, Merken: []})
    }).catch(error => console.log(error))
  }

  const handleMerkenRemoveFilter = (index) => {
    let filterOptionsCopy = JSON.parse(JSON.stringify(filterOptions));
    filterOptionsCopy.Merken.splice(index, 1);
    setFilterOptions(filterOptionsCopy)
  }

  const onUnselectAll = (cate, filterCategoryData) => {
    if(filterOptions?.[cate]?.length > 0) {
      // if more than one item is selected then clear the filtered list by unselecting all
      setFilterOptions({...filterOptions, [cate]: []})
    }
    else {
      // if no items are selected then selected them all
      // simple validation to check whether filterCategoryData is coming through and it exists to avoid exception error
      if(filterCategoryData && filterCategoryData?.length > 0) {
        let allFilters = {
          [cate]: []
        };
        filterCategoryData.forEach(filterData => {
          allFilters?.[cate].push({
            id: filterData?.id,
            name: filterData?.name
          })
        })
        setFilterOptions({...filterOptions, ...allFilters})
      }
    }
  }

  return (
    <Grid container>
      <Grid container item sm={12} md = {11} className = "h-fit">
        <FilterCategory categoryData = {categoryData} filterData={filterData} handleFilterChange = {handleFilterChange} handlePriceFilterChange = {handlePriceFilterChange} handleMerkenSearchUpdate = {handleMerkenSearchUpdate} filterOptions = {filterOptions} handleMerkenFilterChange = {handleMerkenFilterChange} handleMerkenRemoveFilter = {handleMerkenRemoveFilter} onUnselectAll = {onUnselectAll} />
      </Grid>
    </Grid>
  );
}

FilterCategory.propTypes = {
  filterData: PropTypes.array,
  handleFilterChange: PropTypes.func,
  categoryData: PropTypes.array,
  handlePriceFilterChange: PropTypes.func
};

FilterCategory.defaultProps = {
  filterData: [],
  handleFilterChange: () => {},
  categoryData: [],
  handlePriceFilterChange: () => {}
};

SubFilter.propTypes = {
  filterData: PropTypes.object,
  filterCategory: PropTypes.string,
  handleFilterChange: PropTypes.func
};

SubFilter.defaultProps = {
  filterData: {},
  filterCategory: "",
  handleFilterChange: () => {}
};
