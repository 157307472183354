import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@mui/material";
import { BASE_URL, sign_up, client } from '../../../redux/constants/ApiEndPoints';
import axios from 'axios';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../components/success_modal/success_modal';

import LoginTopBar from '../../../components/top_bars/login_top_bar';
import RadioButton from '../../../components/radio_button/radio_button';
import RadioGroup from '../../../components/inputs/radio_group';
import InputText from '../../../components/inputs/text';
import CheckBox from '../../../components/inputs/checkbox';
import SubmitButton from '../../../components/submit_section';

import './register.scss';
import { REGEX } from '../../../constants/constants';

const Heading = (props) => {
    const { label, mb, mainHeadings } = props;
    const headingClass = mainHeadings ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]` : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
    return (
        <div className={headingClass}>
            {label}
        </div>
    )
}

// top most part of the registration form, includes radio button and login form
const EmailPassword = forwardRef((props, ref) => {
    const [ emailErrorState, setEmailErrorState ] = useState(false);
    const [ confirmEmailErrorState, setConfirmEmailErrorState ] = useState(false);
    const [ passwordErrorState, setPasswordErrorState ] = useState(false);
    const [ confirmPasswordErrorState, setConfirmPasswordErrorState ] = useState(false);

    const { rbState, setRBState } = props;
    const emailRef = useRef(0);
    const confirmEmailRef = useRef(0);
    const passwordRef = useRef(0);
    const confirmPasswordRef = useRef(0);

    useImperativeHandle(ref, () => ({
        getValues: () => {
            const email = emailRef.current.getValue();
            const confirmEmail = confirmEmailRef.current.getValue();
            const password = passwordRef.current.getValue();
            const confirmPassword = confirmPasswordRef.current.getValue();
            return { email, confirmEmail, password, confirmPassword };
        },
        setErrors: (email, confirmEmail, password, confirmPassword) => {
            if(typeof email === "boolean") {
                setEmailErrorState(email)
            }
            if(typeof confirmEmail === "boolean") {
                setConfirmEmailErrorState(confirmEmail)
            }
            if(typeof password === "boolean") {
                setPasswordErrorState(password)
            }
            if(typeof confirmPassword === "boolean") {
                setConfirmPasswordErrorState(confirmPassword)
            }
        }
    }))

    return (    
        <div className=' mb-[50px]'>
            <RadioGroup 
                rbState={rbState}
                setRBState={setRBState}
                labels={["PARTICULIER", "ZAKELIJK"]}
                styleProps='text-[#27292C] font-semibold text-[14px]'
                spanStyleProps='pr_radio_group ml-[-20px]'
                spaceBetween='|||'
            />
            <div className='grid_register_page grid_2_columns ms:mt-[33px] mt-[70px]'>
                <InputText 
                    label='EMAIL'
                    name='email'
                    placeHolderText='Email'
                    ref = {emailRef}
                    errorState = {emailErrorState}
                />
                <InputText 
                    label='BEVESTIG EMAIL'
                    name='confirm_email'
                    placeHolderText='Bevestig Email'
                    ref = {confirmEmailRef}
                    errorState = {confirmEmailErrorState}
                />
            </div>
            <div className='grid_register_page grid_2_columns mt-[25px] ms:mt-[0px]'>
                <InputText 
                    label='WACHTWOORD'
                    type='password'
                    name='password'
                    placeHolderText='Wachtwoord'
                    ref = {passwordRef}
                    errorState = {passwordErrorState}
                />
                <InputText 
                    label='BEVESTIG WACHTWOORD'
                    type='password'
                    name='confirm_password'
                    placeHolderText='Bevestig wachtwoord'
                    ref = {confirmPasswordRef}
                    errorState = {confirmPasswordErrorState}
                />
            </div>                 
        </div>
    )
})

// second part of the registration form, address part with optional business related fields
const Address = forwardRef((props, ref) => {    
    const [ nameErrorState, setNameErrorState ] = useState(false);
    const [ lastnameErrorState, setLastnameErrorState ] = useState(false);
    const [ streetErrorState, setStreetErrorState ] = useState(false);
    const [ nrErrorState, setNrErrorState ] = useState(false);
    const [ cityErrorState, setCityErrorState ] = useState(false);
    const [ zipErrorState, setZipErrorState ] = useState(false)
    const [ addressEmailErrorState, setAddressEmailErrorState ] = useState(false);
    const [ telefoonErrorState, setTelefoonErrorState ] = useState(false);
    const [ gsmErrorState, setGsmErrorState ] = useState(false);
    const [ dobErrorState, setDobErrorState ] = useState(false);
    const [ companyErrorState, setCompanyErrorState ] = useState(false);
    const [ companyNumberErrorState, setCompanyNumberErrorState ] = useState(false);
    
    const nameRef = useRef(0);
    const lastnameRef = useRef(0);
    const companyNameRef = useRef(0);
    const nrRef = useRef(0);
    const addressRef = useRef(0);
    const addressNrRef = useRef(0);
    const addressBusRef = useRef(0);
    const cityRef = useRef(0);
    const postalcodeRef = useRef(0);
    const userEmailRef = useRef(0);
    const phoneRef = useRef(0);
    const dateRef = useRef(0);
    const gsmNumberRef = useRef(0);

    const { isBusiness, mb } = props;

    useImperativeHandle(ref, () => ({
        getValues: () => {
            const firstName = nameRef.current.getValue();
            const lastName = lastnameRef.current.getValue();
            const company = isBusiness ? companyNameRef.current.getValue() : null;
            const company_number = isBusiness ? nrRef.current.getValue() : null;
            const phone = phoneRef.current.getValue();
            let dobString = dateRef.current.getValue();
            const dob = dobString;
            const mobile = gsmNumberRef.current.getValue();

            const addresses = [{
                street_1: addressRef.current.getValue(),
                number_1: addressNrRef.current.getValue(),
                number_2: addressBusRef.current.getValue(),
                zip_code: postalcodeRef.current.getValue(),
                city: cityRef.current.getValue(),
                email: userEmailRef.current.getValue()
            }]

            return { firstName, lastName, addresses, company, company_number, phone, dob, mobile }
        },
        setErrors: (firstname, lastname, street, nr, city, zip, addressEmail, telefoon, gsm, dob, companyname, companynumber) => {
            if(typeof firstname === "boolean") {
                setNameErrorState(firstname)
            }
            if(typeof lastname === "boolean") {
                setLastnameErrorState(lastname)
            }
            if(typeof street === "boolean") {
                setStreetErrorState(street)
            }
            if(typeof nr === "boolean") {
                setNrErrorState(nr)
            }
            if(typeof city === "boolean") {
                setCityErrorState(city)
            }
            if(typeof zip === "boolean") {
                setZipErrorState(city)
            }
            if(typeof addressEmail === "boolean") {
                setAddressEmailErrorState(addressEmail)
            }
            if(typeof telefoon === "boolean") {
                setTelefoonErrorState(telefoon)
            }
            if(typeof gsm === "boolean") {
                setGsmErrorState(gsm)
            }
            if(typeof dob === "boolean") {
                setDobErrorState(dob)
            }
            if(typeof companyname === "boolean") {
                setCompanyErrorState(companyname)
            }
            if(typeof companynumber === "boolean") {
                setCompanyNumberErrorState(companynumber)
            }
        }
    }))
    return (
        <div className='mb-[60px]'>
            <Heading mb='27px' label='Huidig adres'/>
            {isBusiness && (
                <div style={{marginTop: '-8px'}} className='grid_register_page grid_2_columns mt-[70px] ms:mt-[24px]'>
                    <InputText 
                        label='BEDRIJFSNAAM'
                        name='first_name'
                        placeHolderText='Bedrijfsnaam'
                        ref = {companyNameRef}
                        errorState = {companyErrorState}
                    />
                    <InputText 
                        label='BTW NR'
                        name='btw_nr'
                        placeHolderText='BE'
                        ref = {nrRef}
                        errorState = {companyNumberErrorState}
                    />
                </div>
            )}
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='NAAM'
                    name='name'
                    placeHolderText='Naam'
                    ref = {nameRef}
                    errorState = {nameErrorState}
                />
                <InputText 
                    label='VOORNAAM'
                    name='voornam'
                    placeHolderText='Voornaam'
                    ref = {lastnameRef}
                    errorState = {lastnameErrorState}
                />
            </div>  
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='address'
                    placeHolderText='Straat'
                    ref = {addressRef}
                    errorState = {streetErrorState}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='nr'
                        placeHolderText='Nr'
                        ref = {addressNrRef}
                        errorState = {nrErrorState}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='bus'
                        placeHolderText='Bus'
                        ref = {addressBusRef}
                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='gemeente'
                    placeHolderText='Gemeente'
                    ref = {cityRef}
                    errorState = {cityErrorState}
                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='postcode'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    ref = {postalcodeRef}
                    errorState = {zipErrorState}
                />
            </div>
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='EMAIL'
                    name='personal_email'
                    placeHolderText='Example@email.com'
                    ref = {userEmailRef}
                    errorState = {addressEmailErrorState}
                />
                <InputText 
                    label='TELEFOON NUMMER'
                    name='phone'
                    placeHolderText='+32'
                    ref = {phoneRef}
                    errorState = {telefoonErrorState}
                />
            </div>   
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='GEBOORTE DATUM'
                    name='date_birth'
                    placeHolderText='DD/MM/JJJJ'
                    ref = {dateRef}
                    errorState = {dobErrorState}
                />
                <InputText 
                    label='GSM NUMMER'
                    name='mobile_number'
                    placeHolderText='+32 4'
                    ref = {gsmNumberRef}
                    errorState = {gsmErrorState}
                />
                {dobErrorState && <p className = "font-[Montserrat] font-medium text-[16px] text-[red]">ongeldige datum DD/MM/JJJJ</p>}
            </div>   
        </div>
    )
})

// third part of the registration form, basic contact info for secondary person
const SecondPersonContact = forwardRef((props, ref) => {
    const [ emailErrorState, setEmailErrorState ] = useState(false);
    const [ telefoonErrorState, setTelefoonErrorState ] = useState(false);

    const secondContactNameRef = useRef(0);
    const secondContactLastnameRef = useRef(0);
    const secondContactEmailRef = useRef(0);
    const secondContactPhoneRef = useRef(0);

    useImperativeHandle(ref, () => ({
        getValues: () => {
            const second_contact = {
                firstName: secondContactNameRef.current.getValue(),
                lastName: secondContactLastnameRef.current.getValue(),
                email: secondContactEmailRef.current.getValue(),
                phone: secondContactPhoneRef.current.getValue()
            }
            return second_contact
        },
        setErrors: (email, telefoon) => {
            if(typeof email === "boolean") {
                setEmailErrorState(email)
            }
            if(typeof telefoon === "boolean") {
                setTelefoonErrorState(telefoon)
            }
        }
    }))
    return (
        <div className='mb-[45px]'>
            <Heading
                mb='27px'
                label='2de contact persoon'
                mainHeadings={false}
            />
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='NAAM'
                    name='name'
                    placeHolderText='Naam'
                    ref = {secondContactNameRef}
                />
                <InputText 
                    label='VOORNAAM'
                    name='voornam'
                    placeHolderText='Voornaam'
                    ref = {secondContactLastnameRef}
                />
            </div>  
             
            <div className='grid_register_page grid_2_columns mt-[25px]'>
                <InputText 
                    label='EMAIL'
                    name='other_email'
                    placeHolderText='Example@email.com'
                    ref = {secondContactEmailRef}
                    errorState = {emailErrorState}
                />
                <InputText 
                    label='TELEFOON NUMMER'
                    name='other_fone'
                    placeHolderText='+32'
                    ref = {secondContactPhoneRef}
                    errorState = {telefoonErrorState}
                />
            </div>  
        </div>
)})

const AddressBilling = forwardRef((props, ref) => {
    const billingAddressRef = useRef(0);
    const billingAddressNrRef = useRef(0);
    const billingAddressBusRef = useRef(0);
    const billingAddressCityRef = useRef(0);
    const billingAddressPostalcodeRef = useRef(0);
    const { mb } = props

    const [ streetErrorState, setStreetErrorState ] = useState(false);
    const [ nrErrorState, setNrErrorState ] = useState(false);
    const [ cityErrorState, setCityErrorState ] = useState(false);
    const [ zipErrorState, setZipErrorState ] = useState(false);

    useImperativeHandle(ref, () => ({
        getValues: () => {
            const billingForm = {
                street_1: billingAddressRef.current.getValue(),
                number_1: billingAddressNrRef.current.getValue(),
                number_2: billingAddressBusRef.current.getValue(),
                city: billingAddressCityRef.current.getValue(),
                zip_code: billingAddressPostalcodeRef.current.getValue(),
            }

            return billingForm;
        },
        setErrors: (street, nr, city, zip) => {
            if(typeof street === "boolean") {
                setStreetErrorState(street)
            }
            if(typeof nr === "boolean") {
                setNrErrorState(nr)
            }
            if(typeof city === "boolean") {
                setCityErrorState(city)
            }
            if(typeof zip === "boolean") {
                setZipErrorState(zip)
            }
        }
    }))

    return (
        <>
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='address'
                    placeHolderText='Straat'
                    ref = {billingAddressRef}
                    errorState = {streetErrorState}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='nr'
                        placeHolderText='Nr'
                        ref = {billingAddressNrRef}
                        errorState = {nrErrorState}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='bus'
                        placeHolderText='Bus'
                        ref = {billingAddressBusRef}

                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='gemeente'
                    placeHolderText='Gemeente'
                    ref = {billingAddressCityRef}
                    errorState = {cityErrorState}

                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='postcode'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    ref = {billingAddressPostalcodeRef}
                    errorState = {zipErrorState}
                />
            </div>
        </>
    )
})

const AddressLiving = forwardRef((props, ref) => {
    const livingAddressRef = useRef(0);
    const livingAddressNrRef = useRef(0);
    const livingAddressBusRef = useRef(0);
    const livingAddressCityRef = useRef(0);
    const livingAddressPostalcodeRef = useRef(0);
    const { mb } = props;

    const [ streetErrorState, setStreetErrorState ] = useState(false);
    const [ nrErrorState, setNrErrorState ] = useState(false);
    const [ cityErrorState, setCityErrorState ] = useState(false);
    const [ zipErrorState, setZipErrorState ] = useState(false);

    useImperativeHandle(ref, () => ({
        getValues: () => {
            const livingForm = {
                street_1: livingAddressRef.current.getValue(),
                number_1: livingAddressNrRef.current.getValue(),
                number_2: livingAddressBusRef.current.getValue(),
                city: livingAddressCityRef.current.getValue(),
                zip_code: livingAddressPostalcodeRef.current.getValue(),
            }
            
            return livingForm;
        },
        setErrors: (street, nr, city, zip) => {
            if(typeof street === "boolean") {
                setStreetErrorState(street)
            }
            if(typeof nr === "boolean") {
                setNrErrorState(nr)
            }
            if(typeof city === "boolean") {
                setCityErrorState(city)
            }
            if(typeof zip === "boolean") {
                setZipErrorState(zip)
            }
        }
    }))
    return (
        <>
            <div className='grid_register_page grid_columns_7_2 mt-[15px]'>
                <InputText 
                    label='ADRES'
                    name='address'
                    placeHolderText='Straat'
                    ref = {livingAddressRef}
                    errorState = {streetErrorState}
                />
                <div className='grid_register_page mini_grid_2_columns'>
                    <InputText 
                        label='NR'
                        labelColor='#fff'
                        name='nr'
                        placeHolderText='Nr'
                        ref = {livingAddressNrRef}
                        errorState = {nrErrorState}
                    />
                    <InputText 
                        label='BUS'
                        labelColor='#fff'
                        name='bus'
                        placeHolderText='Bus'
                        ref = {livingAddressBusRef}

                    />
                </div>
            </div>   
            <div className={`grid_register_page grid_columns_7_2 mb-[${mb}]`}>
                <InputText 
                    label='GEMEENTE'
                    labelColor='#fff'
                    name='gemeente'
                    placeHolderText='Gemeente'
                    ref = {livingAddressCityRef}
                    errorState = {cityErrorState}

                />
                <InputText 
                    label='POSTCODE'
                    labelColor='#fff'
                    name='postcode'
                    inputStyleProps='border border-[#000000] ms:w-[48%] mt-[10px] py-2 px-3'
                    placeHolderText='Postcode'
                    ref = {livingAddressPostalcodeRef}
                    errorState = {zipErrorState}
                />
            </div>
        </>
    )
})

const RegisterPage = () => {
    const [rbState, setRBState] = useState('PARTICULIER');
    const [checkedBilling, setCheckedBilling] = useState(true);
    const [checkedDelivery, setCheckedDelivery] = useState(true);
    const [successModalState, setSuccessModalState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // login form
    const emailFormRef = useRef(0);

    // address form
    const addressFormRef = useRef(0);

    // second person contact form
    const secondPersonFormRef = useRef(0);

    // billing address form 
    const billingAddressFormRef = useRef(0);

    // living address form
    const livingAddressFormRef = useRef(0)

    const navigate = useNavigate();
    
    const handleRegistrationSubmit = () => {
        // login form 
        const emailForm = emailFormRef.current.getValues();
        const { email, confirmEmail, password, confirmPassword } = emailForm;

        // company/user form
        const addressForm = addressFormRef.current.getValues();
        const { firstName, lastName, company, company_number, phone, dob, mobile } = addressForm;
        let { addresses } = addressForm;
        addresses[0].billing = checkedBilling;
        addresses[0].delivery = checkedDelivery;

        // second person contact form
        const second_contact = secondPersonFormRef.current.getValues();
        // check if contact form is filled, otherwise we don't send the form
        let isContactFormFilled = false;
        if(second_contact?.firstname) {
            isContactFormFilled = true;
        }
        else if(second_contact?.lastname) {
            isContactFormFilled = true;
        }
        else if(second_contact?.email) {
            isContactFormFilled = true;
        }
        else if(second_contact?.phone) {
            isContactFormFilled = true;
        }

        // billing form
        if(!checkedBilling) {
            const billingForm = billingAddressFormRef.current.getValues();
            addresses.push(billingForm)
        }
        else {
            addresses.push({...addresses[0]})
        }

        // living form
        if(!checkedDelivery) {
            const livingForm = livingAddressFormRef.current.getValues();
            addresses.push(livingForm)
        }
        else {
            addresses.push({...addresses[0]})
        }
        let isValid = isFormValid(email, confirmEmail, password, confirmPassword, firstName, lastName, addresses[0].street_1, addresses[0].number_1, addresses[0].city, addresses[0].zip_code, addresses[0].email, phone, mobile, dob, second_contact?.email, second_contact.phone, company, company_number);
        let payload = {
            firstName, 
            lastName, 
            email, 
            password,
            type_id: 1,
            business: rbState === "ZAKELIJK" ? true : false,
            company,
            company_number,
            addresses,
            phone,
            dob,
            mobile
        }
        if(isContactFormFilled) {
            payload.second_contact = second_contact;
        }
        if(!addresses[0].billing){
            const billingForm = billingAddressFormRef.current.getValues();
            console.log(billingForm);
            let streetEr = false, numberEr = false, cityEr = false, postCodeEr = false;
            if(billingForm.street_1 === ""){
                streetEr = true;
                isValid = false;
            }if(billingForm.number_1 === ""){
                isValid = false;
                numberEr = true;
            }if(billingForm.city === ""){
                isValid = false;
                cityEr = true;
            }if(billingForm.zip_code === ""){
                isValid = false;
                postCodeEr = true;
            }
            billingAddressFormRef.current.setErrors(streetEr, numberEr, cityEr, postCodeEr)
            console.log('form invalid');
        }

        if(!addresses[0].delivery){
            let valid = true, streetEr = false, numberEr = false, cityEr = false, postCodeEr = false;
            const livingForm = livingAddressFormRef.current.getValues();
            if(livingForm.street_1 === ""){
                streetEr = true;
                isValid = false;
            }if(livingForm.number_1 === ""){
                numberEr = true;
                isValid = false;
            }if(livingForm.city === ""){
                isValid = false;
                cityEr = true;
            }if(livingForm.zip_code === ""){
                isValid = false;
                postCodeEr = true;
            }
            livingAddressFormRef.current.setErrors(streetEr, numberEr, cityEr, postCodeEr)
            console.log('form invalid');
        }
        if(isValid) {
            axios.post(BASE_URL + client, payload).then(response => {
                setSuccessModalState(true)
            }).catch(error => {
                console.log(error);
                if(error?.response?.data){
                    if(error?.response?.data?.data?.message.includes("ER_DUP_ENTRY")){
                        setErrorMessage("Dit e-mailadres is niet geschikt voor gebruik. Gelieven te probeer met een ander e-mailadres.");
                    }
                }
            })
        }
        else {
            // display error
        }
    }

    const isFormValid = (email, confirmEmail, password, confirmPassword, name, lastname, street, nr, city, zip, addressEmail, telefoon, gsm, date, secondContactEmail, secondContactTelefoon, companyName, companyNumber) => {
        let valid = true;
        // email and password form error vars
        let emailError = false, confirmEmailError = false, passwordError = false, confirmPasswordError = false;
        // address form error vars
        let nameError = false, lastnameError = false, streetError = false, nrError = false, cityError = false, zipError = false, addressEmailError = false, telefoonError = false, gsmError = false, dateError = false, companyNameError = false, companyNumberError = false;
        // second contact form error vars
        let secondContactEmailError = false, secondContactTelefoonError = false;
        if(!REGEX.EMAIL.test(email)) {
            valid = false;
            emailError = true;
        }
        if(!REGEX.EMAIL.test(confirmEmail)) {
            valid = false;
            confirmEmailError = true;
        }
        if(email !== confirmEmail) {
            valid = false;
            emailError = true;
            confirmEmailError = true;
        }
        if(password === "" || password?.length < 8) {
            valid = false;
            passwordError = true;
        }
        if(confirmPassword === "" || confirmPassword?.length < 8) {
            valid = false;
            confirmPasswordError = true;
        }
        if(password !== confirmPassword) {
            valid = false;
            passwordError = true;
            confirmPasswordError = true;
        }
        if(!name) {
            valid = false;
            nameError = true;
        }
        if(!lastname) {
            valid = false;
            lastnameError = true;
        }
        if(!street) {
            valid = false;
            streetError = true;
        }
        if(!nr) {
            valid = false;
            nrError = true;
        }
        if(!city) {
            valid = false;
            cityError = true;
        }
        if(!zip) {
            valid = false;
            zipError = true;
        }
        if(!REGEX.EMAIL.test(addressEmail)) {
            valid = false;
            addressEmailError = true;
        }
        if(telefoon || gsm) {
            if(telefoon) {
                if(!REGEX.PHONE.test(telefoon)) {
                    valid = false;
                    telefoonError = true;
                }
            }
            if(gsm) {
                if(!REGEX.PHONE.test(gsm)) {
                    valid = false;
                    gsmError = true;
                }
            }
        }
        else {
            valid = false;
            telefoonError = true;
            gsmError = true;
        }
        if(!date || !REGEX.DATE.test(date)) {
            valid = false;
            dateError = true;
        }
        if(secondContactEmail) {
            if(!REGEX.EMAIL.test(secondContactEmail)) {
                valid = false;
                secondContactEmailError = true;
            }
        }
        if(secondContactTelefoon) {
            if(!REGEX.PHONE.test(secondContactTelefoon)) {
                valid = false;
                secondContactTelefoonError = true;
            }
        }
        if(typeof companyName === "string") {
            if(!companyName) {
                valid = false;
                companyNameError = true;
            }
        }
        if(typeof companyNumber === "string") {
            if(!companyNumber) {
                valid = false;
                companyNumberError = true;
            }
        }
        // trigger an error message beneath submit button
        if(email !== confirmEmail) {
            setErrorMessage("Emails komen niet overeen")
        }
        else if(password !== confirmPassword) {
            setErrorMessage("Wachtwoorden komen niet overeen")
        }
        else if(!telefoon && !gsm) {
            setErrorMessage("Nog niet alle verplichte velden zijn ingevuld")
        }
        else if(!valid){
            setErrorMessage("Nog niet alle verplichte velden zijn ingevuld")
        }
        else {
            setErrorMessage("")
        }
        emailFormRef.current.setErrors(emailError, confirmEmailError, passwordError, confirmPasswordError)
        addressFormRef.current.setErrors(nameError, lastnameError, streetError, nrError, cityError, zipError, addressEmailError, telefoonError, gsmError, dateError, companyNameError, companyNumberError)
        secondPersonFormRef.current.setErrors(secondContactEmailError, secondContactTelefoonError)
        return valid;
    }

    const handleSuccess = () => {
        setSuccessModalState(false);
        navigate("/info")
    }

    return (
        <div>  
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <div className="register_body mx-[12%] mt-[40px] text-center">
                        <Heading mb='27px' label='Registreren'/>
                        <EmailPassword rbState = {rbState} setRBState = {setRBState} ref = {emailFormRef} />
                        <Address isBusiness = {rbState === "ZAKELIJK"} ref = {addressFormRef} />
                        <SecondPersonContact ref = {secondPersonFormRef} />
                        <div className='mt-[45px]'>
                            <div className='mb-[20px]'>
                                <Heading
                                    mb='10px'
                                    label='Facturatieadres'
                                />
                                <CheckBox
                                    label='identiek aan het huidig adres'
                                    name='checkbox_1'
                                    checked={checkedBilling}
                                    setChecked={setCheckedBilling}
                                    parentClassProp = 'w-full flex justify-center'
                                />
                            </div>
                            {!checkedBilling && 
                                <AddressBilling
                                    mb='50px'
                                    ref = {billingAddressFormRef}
                                />
                            }
                            <div className='mb-[25px]'>
                                <Heading
                                    mb='10px'
                                    label='Leveringsadres'
                                />
                                <CheckBox 
                                    checked={checkedDelivery}
                                    setChecked={setCheckedDelivery}
                                    label='identiek aan het huidig adres'
                                    name='checkbox_2'
                                    parentClassProp = 'w-full flex justify-center'
                                />
                            </div>
                            {!checkedDelivery &&
                                <AddressLiving
                                    mb='50px'
                                    ref = {livingAddressFormRef}
                                />
                            }
                        </div>
                        <SubmitButton submitText='Volgende stap' link='/checkout' onClick = {handleRegistrationSubmit} alternative />
                        {errorMessage && (
                            <p className = "font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{errorMessage}</p>
                        )}
                    </div>
                </Container>
            </Grid>
            <SuccessModal modalState = {successModalState} message = "Bevestigingsmail verzonden" onTimeout = {handleSuccess} timeoutDuration = {4000} />
        </div>
    )
}

export default RegisterPage;

Heading.propTypes = {
    label: PropTypes.string,
    mb: PropTypes.string,
    mainHeadings: PropTypes.bool
}

Heading.defaultProps = {
    label: "",
    mb: "",
    mainHeadings: true
}

EmailPassword.propTypes = {
    rbState: PropTypes.bool,
    setRBState: PropTypes.func
}

EmailPassword.defaultProps = {
    rbState: false,
    setRBState: () => {}
}

Address.propTypes = {
    isBusiness: PropTypes.bool,
    mb: PropTypes.string
}

Address.defaultProps = {
    isBusiness: false,
    mb: ""
}

AddressBilling.propTypes = {
    mb: PropTypes.string
}

AddressBilling.defaultProps = {
    mb: ""
}

AddressLiving.propTypes = {
    mb: PropTypes.string
}

AddressLiving.defaultProps = {
    mb: ""
}
