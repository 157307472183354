import React from "react";

// image imports here
import imageTick from "../../assets/icons/check.svg";
import imageCross from "../../assets/icons/close.svg";

import "./promotions_card.scss";

const PromotionsCard = ({ item, key, handlePromotionImageClick }) => {
  return (
    <div id="promotions-card" key={key}>
      <div className="inventory-item" onClick = {() => handlePromotionImageClick(item?.image || null)}>
        <div className="item">
          <div className="image-section flex justify-center mb-[20px]">
            <img src={item.image} alt="Pin" />
          </div>
        </div>
      </div>
      <div className="pl-[15px] pr-[15px] pb-[20px]">
        <div className="description-section mt-[30px]">
          <div className="font-semibold text-lg title-text pb-[5px]">
            {item.name}
          </div>
          <div className="linkDiv">
            <div
              className="font-extralight description-text"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
          {/* <div className="font-extralight description-text">
            {item.description}
          </div> */}
          <div className="font-light text-base bottom-text ml-[10px] mt-[10px] flex">
            <span className="mt-[7px]">
              <img
                src={item.izegem ? imageTick : imageCross}
                alt="Pin"
                width={item.izegem ? 14 : 12}
              />
            </span>
            <span className="ml-[8px]">Izegem</span>
            <span className="ml-[20px] mt-[7px]">
              <img
                src={item.massenhoven ? imageTick : imageCross}
                alt="Pin"
                width={item.massenhoven ? 14 : 12}
              />
            </span>
            <span className="ml-[8px]">Massenhoven</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionsCard;
