import React, { useState } from "react";
import {Grid, InputAdornment, TextField} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { isEmpty, throttle } from 'lodash';
import axios from "axios";
import { BASE_URL, clientNewsletter } from "../../../redux/constants/ApiEndPoints";
import SuccessModal from "../../../components/success_modal/success_modal";

import imageInstagram from '../../../assets/images/footer/instagram.svg';
import imageFacebook from '../../../assets/images/footer/facebook.svg';
import imagePintrest from '../../../assets/images/footer/pinterest.svg';
import imageTwitter from '../../../assets/images/footer/twitter.svg';
import imageHelp from '../../../assets/images/footer/info.svg';
import interierLogo from '../../../assets/images/footer/logo-footer.png';
import checkFilled from "./../../../assets/icons/check-filled.svg";
import agiflyLogo from "../../../assets/images/footer/Logo_White_1.svg";

import "../footer.scss";
import { REGEX } from "../../../constants/constants";

const useStyles = makeStyles(theme => ({
    notchedOutline: {
        borderWidth: "0px",
        borderColor: "grey !important"
    }
}));

const FooterLower = ({
    footerData,
}) => {
    const classes = useStyles();
    const [showInfoPopup, toggleInfoPopup] = useState('');
    const [emailInputState, setEmailInputState] = useState("");
    const [newsletterSuccessModalState, setNewsletterSuccessModalState] = useState(false)
    const [emailInputErrorState, setEmailInputErrorState] = useState("")

    const array1 = footerData?.slice(0, 4);
    const array2 = footerData?.slice(4, 8);

    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const handleInterieurLogoClick = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    const sendNewsletter = () => {
        const isValid = emailFieldValidation();
        if(emailInputState && isValid) {
            axios.get(BASE_URL + clientNewsletter + emailInputState, {headers}).then(response => {
                setNewsletterSuccessModalState(true)
            }).catch(error => {
                if(error.response.status === 500) {
                    setEmailInputErrorState("E-mail bestaat al");
                }
            })
        }
    }

    const sendNewsletterKeyevent = (event) => {
        if(emailInputState && event.keyCode === 13) {
            const isValid = emailFieldValidation()
            if(isValid) {
                axios.get(BASE_URL + clientNewsletter + emailInputState, {headers}).then(response => {
                    setNewsletterSuccessModalState(true)
                }).catch(error => {
                    if(error.response.status === 500) {
                        setEmailInputErrorState("E-mail bestaat al");
                    }
                })
            }
        }
    }

    const successModalTimeout = () => {
        setNewsletterSuccessModalState(false);
        setEmailInputState("")
    }

    const emailFieldValidation = () => {
        if(emailInputState === "") {
            setEmailInputErrorState("Veld is leeg")
            return false;
        }
        else if(!REGEX.EMAIL.test(emailInputState)) {
            setEmailInputErrorState("E-mail is niet geldig")
            return false;
        }
        else {
            setEmailInputErrorState("");
            return true;
        }
    }

    return (
        <div>
            <div className="section-1">
                <Grid container>
                    <Grid item md={2} className="item" style={{paddingTop: 40}}>
                        <Link to="/">
                            <img src={interierLogo} className="mt-[5px] logo-interier cursor-pointer" alt="Instagram" width={170} onClick = {handleInterieurLogoClick} />
                        </Link>
                    </Grid>
                    <Grid item md={3} className="item" style={{paddingTop: 40}}>
                        <div className="button-wrapper">
                            <Link to='/afspraak-form'>
                                <button className="button-1">
                                    Maak een afspraak
                                </button>
                            </Link>
                        </div>
                    </Grid>
                    <Grid item md={2} className="item" style={{paddingTop: 35}}>
                        <Grid container className="social-media">
                            <Grid item md={3}>
                                <a href="https://www.instagram.com/topinterieur/" target="_blank" rel="noreferrer">
                                    <img src={imageInstagram} alt="Instagram" width={24}/>
                                </a>
                            </Grid>
                            <Grid item md={3}>
                                <a href="https://www.pinterest.com/topinterieur/" target="_blank" rel="noreferrer">
                                    <img src={imagePintrest} alt="Instagram" width={24}/>
                                </a>
                            </Grid>
                            <Grid item md={3}>
                                <a href="https://www.facebook.com/topinterieur/" target="_blank" rel="noreferrer">
                                    <img src={imageFacebook} alt="Instagram" width={13}/>
                                </a>
                            </Grid>
                            <Grid item md={3}>
                                <a href="https://twitter.com/topinterieur/" target="_blank" rel="noreferrer">
                                    <img src={imageTwitter} alt="Instagram" width={30}/>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={5} className="item" style={{padding: '10px 30px 40px', marginTop: '-10px' }}>
                        <Grid container>
                            <Grid item md={12}>
                                <p style={{paddingBottom: '10px'}}>Schrijf in voor onze nieuwsbrief</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid container item md={12}>
                                <TextField
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    placeholder="Jouw email adres"
                                    value = {emailInputState}
                                    onChange = {event => setEmailInputState(event.target.value)}
                                    onKeyUp = {sendNewsletterKeyevent}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline
                                        },
                                        className: "search-field-footer-lower",
                                        endAdornment: (
                                            <InputAdornment position="end" className = "cursor-pointer" onClick = {sendNewsletter}>
                                                <img src={checkFilled} alt="Pin" width={28} className="text-redOrange-100" />
                                            </InputAdornment>
                                        )
                                    }}
                                />

                            </Grid>
                        </Grid>
                        <Grid container justifyContent = "center">
                            {emailInputErrorState && emailInputErrorState !== "" && (
                                <p className = "font-[Montserrat] font-medium text-[16px] text-[red] mt-[15px]">{emailInputErrorState}</p>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div className="section-2">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4" style={{ minHeight: 300 }}>
                    {array1?.map((dataItem) => {
                        return (
                            <div className="p-3 item">
                                <div className="timing">
                                    {!isEmpty(dataItem?.image) && (
                                        <>
                                            <img src={dataItem?.image} alt="Pin" width={52}/>
                                            <br/>
                                        </>
                                    )}

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{position: "absolute"}}>
                                            <span className="heading help-heading" dangerouslySetInnerHTML={{ __html: dataItem?.title }} />
                                            {dataItem?.show && (
                                                <div style={{
                                                    position: 'absolute',
                                                    right: -30,
                                                    top: 0,
                                                }}>
                                                    <img
                                                        src={imageHelp}
                                                        alt="Pin"
                                                        width={22}
                                                        onMouseEnter={() => toggleInfoPopup(dataItem?.id)}
                                                        onMouseLeave={() => toggleInfoPopup('')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '2px 0 5px 5px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 30 }}>
                                        {showInfoPopup === dataItem?.id ? (
                                            <div style={{ backgroundColor: '#fd3f315c' }} className="p-[20px]">
                                                <span dangerouslySetInnerHTML={{ __html: dataItem?.description }} />
                                            </div>
                                        ) : (
                                            <>
                                                {dataItem?.footerText?.map((text) => {
                                                    return (
                                                        text?.show && (
                                                            <div className="item-text">
                                                                {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                                            </div>
                                                        )
                                                    )
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="section-2 mt-[20px]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"  style={{ minHeight: 300 }}>
                    {array2?.map((dataItem) => {
                        return (
                            <div className="p-3 item">
                                <div className="timing">
                                    <div className="h-[52px] mb-[30px]">
                                        {!isEmpty(dataItem?.image) && (
                                            <img src={dataItem?.image} alt="Pin" style={{ height: '100%' }} />
                                        )}
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{position: "absolute"}}>
                                            <span className="heading help-heading" dangerouslySetInnerHTML={{ __html: dataItem?.title }} />
                                            {dataItem?.show && (
                                                <div style={{
                                                    position: 'absolute',
                                                    right: -30,
                                                    top: 0,
                                                }}>
                                                    <img
                                                        src={imageHelp}
                                                        alt="Pin"
                                                        width={22}
                                                        onMouseEnter={() => toggleInfoPopup(dataItem?.id)}
                                                        onMouseLeave={() => toggleInfoPopup('')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '2px 0 5px 5px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 30 }}>
                                        {showInfoPopup === dataItem?.id ? (
                                            <div style={{ backgroundColor: '#fd3f315c' }} className="p-[20px]">
                                                <span dangerouslySetInnerHTML={{ __html: dataItem?.description }} />
                                            </div>
                                        ) : (
                                            <>
                                                {dataItem?.footerText?.map((text) => {
                                                    return (
                                                        text?.show && (
                                                            <div className="item-text">
                                                                {<span dangerouslySetInnerHTML={{ __html: text?.text }} />}
                                                            </div>
                                                        )
                                                    )
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="text-center">
                <span className="item-text-footer">
                    <Link to="/terms-conditions">
                        <span onClick={() => window.scrollTo(0, 0)}>
                            Algemene voorwaarden
                        </span>
                    </Link>
                    &nbsp; | &nbsp;</span>
                <span className="item-text-footer">
                    <Link to="/cookies#privacy">
                        <span onClick={() => window.scrollTo(0, 0)}>
                            Privacy Policy
                        </span>
                    </Link>
                    &nbsp; | &nbsp;
                </span>
                <span className="item-text-footer">
                    <Link to="/cookies">
                        <span onClick={() => window.scrollTo(0, 0)}>
                            Cookiebeleid
                        </span>
                    </Link>
                </span>
                <span className="item-text-footer" style={{ width: 112, float: 'right' }}>
                    <Grid container>
                        <Grid item={9}>Made by </Grid>
                        <Grid item={3}>
                            <a href="https://agifly.be/" target="_blank">
                                <img src={agiflyLogo} alt="" height={25} width={50} />
                            </a>
                        </Grid>
                    </Grid>
                </span>
            </div>
            <SuccessModal message = "Succesvol ingeschreven voor onze nieuwsbrief!" modalState = {newsletterSuccessModalState} onTimeout = {successModalTimeout} timeoutDuration = {3000} />
        </div>
    )
};

export default FooterLower;
