import React from "react";
import CustomCheckbox from "../../../components/custom_checkbox/customCheckbox";

export default function CheckboxList (props) {
  let { checkboxList, handleCheckbox } = props;

  return (
    <div className="py-14">
        <div className="jobs-checkbox-container">
            {checkboxList.map((obj, index) => (
                <CustomCheckbox obj={obj} handleCheckbox={handleCheckbox} />
            ))}
        </div>
    </div>
  );
}

