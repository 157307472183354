import React from "react";
import Slider from "./slider";

import "./main_carousel.scss";

const Carousel = ({ imgs }) => {
  let videoTypes = [
    "MP4",
    "M4P",
    "M4V",
    "MPG",
    "MP2",
    "MPEG",
    "MPE",
    "MPV",
    "OGG",
    "AVI",
    "WMV",
    "MOV",
    "QT",
    "FLV",
    "SWF",
    "AVCHD",
    "WEBM",
  ];

  const isImage = (img) => {
    let type = img.split(".").pop();
    return videoTypes.includes(type.toUpperCase()) ? false : true;
  };

  return (
    <div>
      <Slider>
        {imgs.map((img) => {
          return isImage(img) ? (
            <img className="w-full h-full object-cover" alt="slider" src={img} />
          ) : (
            <video className="w-full h-full" autoPlay muted loop>
              <source src={img} type="video/mp4"></source>{" "}
            </video>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
