import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { connect } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { getAllSales } from "../../redux/actions/sale";
import Pagination from "../../components/pagination";
import BreadcrumbsItem from "./../../components/breadcrumb/breadcrumb";
import SalesCard from "../../components/sales_card";
import Filter from "./components/filter";

import "./sales.scss";

const sortList = [
  {
    id: 1,
    name: "Aanbeloven",
    value: "REC"
  },
  {
    id: 2,
    name: "Prijs oplopend",
    value: "ASC"
  },
  {
    id: 3,
    name: "Prijs aflopend",
    value: "DESC"
  },
  {
    id: 4,
    name: "Naam A-Z",
    value: "ASC"
  },
  {
    id: 5,
    name: "Naam Z-A",
    value: "DESC"
  }
]

const SalesPage = ({ sale, getAllSales }) => {
  const [sortFilterState, setSortFilterState] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sales, setSales] = useState([]);
  const [selectedSortValue, setSelectedSortValue] = useState(0)
  const [filterOptions, setFilterOptions] = useState({
    Winkel: [],
    Merken: [],
    price: [0, 55000],
  })

  const userData = JSON.parse(localStorage.getItem("user-data"));
  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/sales-page", label: "Sales", active: true }
    ],
  };

  useEffect(() => {
    filterData();
  }, [filterOptions, selectedSortValue])

  useEffect(() => {
    let totalProducts = sale.all_sales?.total;
    let productsList = sale.all_sales?.data;
    let pages = Math.ceil(totalProducts / 20);
    setSales(productsList);
    setPages(pages);
  }, [sale.all_sales]);

  const getPagesCount = () => {
    let pageCount = 0;
    let totalProducts = sale.all_sales?.total;
    let pages = Math.ceil(totalProducts / 20);

    if (sales?.length && currentPage < pages ) {
      pageCount = currentPage * 20;
    } else if (sales?.length && currentPage >= pages) {
      pageCount = totalProducts;
    }

    return pageCount;
  };

  const filterData = (filterOptionsNew, page) => {
    const urlParams = window.location.search;
    if(validateFilterOptions(filterOptionsNew || null)) {
      // create an array to pass filter options to axios, with proper body params
      let filterQuery = {};
      if(filterOptionsNew) {
        for(const option in filterOptionsNew) {
          if(option === "Merken") {
            filterQuery.brand = [];
            filterOptionsNew[option].forEach(selectedBrand => {
              filterQuery.brand.push(selectedBrand?.id)
            })
          }
          else {
            filterQuery[convertFilterCategoryNames(option)] = filterOptionsNew[option];
          }
        }
      }
      else {
        for(const option in filterOptions) {
          if(option === "Merken") {
            filterQuery.brand = [];
            filterOptions[option].forEach(selectedBrand => {
              filterQuery.brand.push(selectedBrand?.id)
            })
          }
          else {
            filterQuery[convertFilterCategoryNames(option)] = filterOptions[option];
          }
        }
      }
      if(selectedSortValue !== 0) {
        if(selectedSortValue === 1 || selectedSortValue === 2) {
          filterQuery.order_price = sortList?.[selectedSortValue]?.value
        }
        else if(selectedSortValue === 3 || selectedSortValue === 4) {
          filterQuery.brand_name = sortList?.[selectedSortValue]?.value
        }
        else if(selectedSortValue === 5) {
          filterQuery.promotion = sortList?.[selectedSortValue]?.value
        }
      }
      setCurrentPage(page || 1)
      getAllSales(page || 1, userData?.id, filterQuery)
    }
  }

  const handlePagination = (current) => {
    setCurrentPage(current);
    getAllSales(current, userData?.id);
  };

  const validateFilterOptions = (filterOptionsNew) => {
    // check to see if there are any items in the arrays
    let itemExists = false;
    if(filterOptionsNew) {
      for(const key in filterOptionsNew) {
        if(filterOptionsNew && filterOptionsNew[key]) {
          itemExists = true;
        }
      }
    }
    else {
      for(const key in filterOptions) {
        if(filterOptions && filterOptions[key]) {
          itemExists = true;
        }
      }
    }
    return itemExists;
  }

  const convertFilterCategoryNames = value => {
    if(value === "Ruimtes") {
      return "room";
    }
    else if(value === "Woonstijl") {
      return "style";
    }
    else if(value === "Materiaal") {
      return "material";
    }
    else if(value === "Winkel") {
      return "location";
    }
    else if(value === "Merken") {
      return "brand";
    }
    else if(value === "price") {
      return "price";
    }
    else if(value === "search") {
      return "search";
    }
    else {
      return "";
    }
  }

  const handleSortItemClick = index => {
    setSelectedSortValue(index);
    setSortFilterState(false)
  };

  return (
    <div id="sales-page">
      <Grid container className="top-section pb-[15px]">
        <Container maxWidth="lg">
          <Grid item md={12}>
            <BreadcrumbsItem options={options} />
          </Grid>

          <Grid item md={12}>
            <h3 className="top-text-heading">Sales</h3>
            {/* <div className="top-text">
              Een nieuw salon kopen? Bij Top Interieur kan het. Ontdek hier een
              brede waaier salons, <br />
              zetels, hoeksalons, relaxen en longchairs in leder, lederlook en
              stof.
              <br />
              Kom ook eens langs in één van onze toonzalen en bespreek alle
              mogelijke opstellingen,
              <br />
              kleuren en afwerkingen van uw salon met onze ervaren
              interieurarchitecten.
              <br />
            </div> */}
          </Grid>
        </Container>
      </Grid>
      <Container maxWidth="lg">
        <Grid container className="pt-[17px]">
          <Grid container item sm = {12} md={3}>
            <Filter filterOptions = {filterOptions} setFilterOptions = {setFilterOptions} />
          </Grid>
          <Grid item md={9}>
            <div className="sales-list-top-filter mt-[25px] md:mt-[0px]">
              <Grid container className="pb-[17px] lg:justify-start justify-between">
                <Grid item md={7}>
                  <div className="w-[150px] md:w-[210px] flex flex-col relative bg-white z-50">
                    <div
                      className="w-full h-[25px] flex justify-between items-center cursor-pointer box-border lg:px-[10px]"
                      onClick={() => setSortFilterState(!sortFilterState)}
                    >
                      <p className="font-montserrat font-regular text-[14px] text-[#27292C] tracking-[0.7px]">
                        {sortList[selectedSortValue]?.name}
                      </p>
                      <div
                        className="animated"
                        style={{
                          transform: sortFilterState
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <IoIosArrowDown color="#C98468" size="1.25rem" />
                      </div>
                    </div>
                    <div
                      className="w-full flex flex-col sales-dropdown-container absolute top-[0px] left-[0px] bg-white animated overflow-hidden"
                      style={{ opacity: sortFilterState ? 1 : 0 }}
                    >
                      <div
                        className="w-full h-[25px] flex justify-between items-center cursor-pointer box-border px-[10px]"
                        onClick={() => setSortFilterState(!sortFilterState)}
                      >
                        <p className="font-montserrat font-regular text-[14px] text-[#27292C] tracking-[0.7px]">
                          {sortList[selectedSortValue]?.name}
                        </p>
                        <div
                          className="animated"
                          style={{
                            transform: sortFilterState
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        >
                          <IoIosArrowDown color="#C98468" size="1.25rem" />
                        </div>
                      </div>
                      <div className="flex flex-col w-full bg-white box-border p-[10px]">
                        {sortList && sortList.map((sortItem, sortIndex) => (
                          <p className="font-montserrat font-light text-[13px] text-[#27292C] tracking-[0.7px] cursor-pointer leading-[25px]" onClick = {() => handleSortItemClick(sortIndex)}>
                            {sortItem?.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item md={5} className="sort-right z-[89]">
                  <Pagination
                    total={pages}
                    current={currentPage}
                    pagination={(crPage) => handlePagination(crPage)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="right-total-items mt-[10px] mb-[30px]">
              {`${getPagesCount()} van ${sale.all_sales?.total} producten`}
            </div>

            <Grid container className="sales">
              {sales?.map((obj, index) => (
                <Grid container item md={4} className="sales-item" key={index}>
                  <SalesCard product={obj} currentPage={currentPage} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ sale, loader }) => ({ sale, loader });
export default connect(mapStateToProps, { getAllSales })(SalesPage);
