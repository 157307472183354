import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import './index.scss';

const DropDown= ({
    contentArr,
    width,
    class_,
    label,
    labelProps,
    initialValue,
    onChange,
    name,
    enablePlaceholder = false,
    placeholderValue = "Kies uit de lijst",
    errorState = false
}) => {

    const [selected, setSelected] = useState(enablePlaceholder ? placeholderValue : 0);

    useEffect(() => {
        if(!enablePlaceholder) {
            if(initialValue) {
                setSelected(initialValue)
            }
        }
    }, [initialValue])

    const handleChange = (event) => {
        const { value } = event.target;
        setSelected(value?.id || value);
        onChange(value?.id || value);
    }

    return (
        <div className='input_select_component'>
            <div className={`${labelProps} ${errorState ? "text-[red]" : "text-black"}`}>{label}</div>
            <select
                required
                className={`w-${width} ${class_} text-left basket_drop_down font-montserrat`}
                onChange={handleChange}
                value = {selected}
                style={{ lineHeight: 1, borderColor: errorState ? "red" : "#D0CCC6"}}
                name={name}
            >
                {enablePlaceholder && (
                    <option value = {placeholderValue} disabled selected hidden>{placeholderValue}</option>
                )}
                {contentArr.map((value,index) =>
                    <option
                        value={value?.id || value}
                        key={value?.id || index}
                    >
                        {value?.name || value}
                    </option>
                )}
            </select>
        </div>
    )
}

export default DropDown;

DropDown.propTypes = {
    initialValue: PropTypes.number,
    onChange: PropTypes.func
}

DropDown.defaultProps = {
    initialValue: 1,
    onChange: () => {}
}
