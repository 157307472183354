import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LockModal from '../../../components/lock_modal/lock_modal';

import './login.scss';
import axios from "axios";
import {BASE_URL, forgot_password} from "../../../redux/constants/ApiEndPoints";
import { trim } from 'lodash';
import { REGEX } from '../../../constants/constants';

const ForgetPassword = (props) => {
    const {forgotPasswordModalState, setForgotPasswordModalState} = props;
    const [forgotPasswordState, setForgotPasswordState] = useState("")
    const [errorStates, setErrorStates ] = useState({
        email: false,
    })
    const navigate = useNavigate();

    const handleForgotPasswordModalClose = () => {
        setForgotPasswordState("")
        setForgotPasswordModalState(false)
    }
    const handleForgotPasswordUpdate = () => {
        if(validateForm(forgotPasswordState)) {
            axios.post(BASE_URL + forgot_password, {
                email: forgotPasswordState,
                type: 1
            }).then(response => {
                navigate(0)
            }).catch(error => console.log(error))
        }
    }

    const validateForm = (email) => {
        const emailError = email ? REGEX.EMAIL?.test(email) : false;
        const isValid = emailError ? true : false;
        setErrorStates({
            email: !emailError,
        })
        return isValid;
    }
    return (
       <LockModal heading = "Wachtwoord vergeten" subheader = "Geef hier het e-mailadres in dat is gekoppeld aan uw top interieur-account." buttonText = "Email versturen" openState = {forgotPasswordModalState} onExit = {handleForgotPasswordModalClose} onClick = {handleForgotPasswordUpdate} subTextClass = "text-center text-[14px] mb-[15px]">
           <div className = "flex flex-col w-full my-[17px]">
               <p className = "font-[Montserrat] text-[14px] text-black font-semibold">EMAIL</p>
               <input className = "h-[41px] w-full border-[1px] border-[black] box-border rounded-[3px] indent-[15px] font-[Montserrat] text-[14px] text-black font-medium" name = "oldPassword" value = {forgotPasswordState} onChange = {event => setForgotPasswordState(event.target.value)} 
               style={{borderColor: errorStates.email? 'red': 'black'}}/>
           </div>
       </LockModal>
    )
}
export default ForgetPassword;
