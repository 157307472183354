import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import "./inputs.scss";

const InputText = forwardRef((props, ref) => {
  const [inputState, setInputState] = useState("");

  const {
    parentStyleProps,
    labelStyleProps,
    inputStyleProps,
    name,
    label,
    labelColor,
    type,
    placeHolderText,
    handleChange,
    value,
    id,
    disabled,
    errorState
  } = props;
  const textColor = labelColor === "#fff" ? "white" : `[${labelColor}]`;

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return inputState;
    },
    setValue: initValue => {
      initValue && setInputState(initValue)
    }
  }));

  return (
    <div className={parentStyleProps}>
      <label
        className={`${labelStyleProps} text-${textColor} ms:text-[#27292C] mt-1`}
        for={id}
      >
        {label}
      </label>
      <input
        disabled = {disabled}
        key={id}
        className={`${inputStyleProps} bg-white`}
        id={id}
        name={name}
        type={type}
        placeholder={placeHolderText}
        value={value || inputState}
        onChange={(e) => handleChange ? handleChange(e) : setInputState(e.target.value)}
        style = {{borderColor: errorState ? "red" : "black"}}
      />
    </div>
  );
});

export default InputText;

InputText.defaultProps = {
  parentStyleProps: "text-left ms:mb-[17px]",
  labelStyleProps: "font-[Montserrat] font-semibold text-[14px] block",
  inputStyleProps: "border border-[#000000] w-full mt-[10px] py-2 px-3",
  labelColor: "#27292C",
  type: "text",
  placeHolderText: "",
  name: "",
  label: "",
  handleChange: null,
  disabled: false,
  errorState: false
};

InputText.propTypes = {
  parentStyleProps: PropTypes.string,
  labelStyleProps: PropTypes.string,
  inputStyleProps: PropTypes.string,
  labelColor: PropTypes.string,
  type: PropTypes.string,
  placeHolderText: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  errorState: PropTypes.bool
};
