import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import Tabs from "../../components/top_tabs";
import PropTypes from "prop-types";
import LoginPage from "../authentication/login/login";
import axios from "axios";
import { BASE_URL, getSingleClient } from "../../redux/constants/ApiEndPoints";

// tab content
import Orders from "./orders/orders";
import Cart from "./cart/cart";
import Favorites from "./favorites/favorites";
import Information from "./information/information";
import Edit from "./edit/edit";

export default function UserAccount(props) {
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState(1);
    const [ infoEditState, setInfoEditState ] = useState(false);
    const [ clientData, setClientData ] = useState({});

    const userData = JSON.parse(localStorage.getItem('user-data'))
    const { type } = props;
    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    
    useEffect(() => {
        if(userData?.id) {
            axios.get(BASE_URL + getSingleClient + userData?.id, {headers}).then(response => {
                console.log(response.data.data[0][0])
                setClientData(response.data.data[0][0])
            }).catch(error => console.log(error))

            setIsLoggedIn(true);
        }
        else {
            setIsLoggedIn(false)
        }
    }, [])

    useEffect(() => {
        if(type && typeof type === "string") {
            switch(type) {
                case "orders": 
                    setSelectedTab(0);
                    setInfoEditState(false);
                    break;
                case "cart": 
                    setSelectedTab(1);
                    setInfoEditState(false);
                    break;
                case "favorites": 
                    setSelectedTab(2);
                    setInfoEditState(false);
                    break;
                case "info": 
                    setSelectedTab(3);
                    setInfoEditState(false);
                    break;
                case "info/edit":
                    setSelectedTab(3);
                    setInfoEditState(true);
                    break;
                default: 
                    setSelectedTab(0);
                    setInfoEditState(false)
            }
        }
    }, [type])

    return(
        <Grid container direction = "column" justifyContent = "center" alignItems = "center">
            <Tabs selectedTab={selectedTab} showHeader = {isLoggedIn ? `${userData?.firstName} ${userData?.lastName}` : null} />
            <Container maxWidth = "lg">
                {isLoggedIn ? (
                    <>  
                        {selectedTab === 0 && (
                            <Orders />
                        )}
                        {selectedTab === 1 && (
                            <Cart />
                        )}
                        {selectedTab === 2 && (
                            <Favorites />
                        )}
                        {selectedTab === 3 && !infoEditState && (
                            <Information clientData = {clientData} isLoggedIn = {isLoggedIn} />
                        )}
                        {selectedTab === 3 && infoEditState && (
                            <Edit clientData = {clientData} isLoggedIn = {isLoggedIn} />
                        )}
                    </>
                ) : (
                    <LoginPage />
                )}
            </Container>
        </Grid>
    )
}

UserAccount.propTypes = {
    type: PropTypes.string
}

UserAccount.defaultProps = {
    type: "info"
}