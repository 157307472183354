/* BASE URL */
export const BASE_URL =
  "https://backend.develop.topinterieur.flykube.agifly.cloud/api/v1";

// export const BASE_URL =
// "https://backend.middleware.topinterieur.flykube.agifly.cloud/api/v1";
/* Authentication */
export const sign_in = `/auth/sign_in`;
export const sign_up = `/auth/sign_up`;
export const change_password = `/auth/change_password`;
export const forgot_password = `/auth/forgot_password`;
export const reset_password = `/auth/reset_password`;
export const registration_confirm = `/client/confirm/`;
export const validateToken = `/auth/validate_token/`;

/* Products */
export const get_products = `/product`;
export const getProduct = `/product/show/`
export const getMostViewedProducts = `/product/most/viewed`;
export const viewProduct = `/product/views`;

/* Sales */
export const get_sales = `/sales`;
export const getSalesItem = `/sales/show/`;

/* Emails */
export const send_appointment_email = `/testemails/send_appointment_email`;
export const job_application_email = `/testemails/job_application_email`;

/* Category */
export const category = `/category`;

/* Client */
export const client = `/client`;
export const getSingleClient = `/client/show/`;
export const updateClient = `/client/update/`;
export const clientNewsletter = `/client/subscribe/`;

/* Menu */
export const menu = "/menu";
export const getPdfImages = "/menu/images/";
export const getMenuCategory = "/menu/show/";

/* Vacatures ( Jobs )*/
export const get_vacatures = `/vacatures`;
export const get_all_jobs = `/job`;
export const get_job = `/job/show`;

/* Carousel */
export const get_carousels = `/carousel`;

/* Box */
export const get_boxes = `/box`;

/* Collection */
export const get_collections = `/collection`;

/* Brand */
export const get_brands = `/brand`;

/* Wishlist */
export const getWishlist = `/wish/list/`;
export const createWishlist = `/wish/list`;
export const updateWishlist = `/wish/list/update/`;
export const deleteWishList = `/wish/list/delete/`;

/* Page */
export const get_page_details = `/project/page`;

/* Project Category */
export const all_project_categories = `/project/category`;

/* Project */
export const all_projects = `/project`;

/* Promotion */
export const get_promotions = `/promotion`;

/* Cart */
export const getCart = `/cart/`;
export const updateCart = `/cart/update/`;
export const createCart = `/cart/`;

/* Order */
export const getUserOrder = `/order/user/`;
export const createOrder = `/order`;
export const getDeliveryOptions = `/order/page/delivery/options`
export const getOrderLocations = `/order/page/pickup`
export const getAdvanceDetails = `/order/page/advance/conditions`
export const getThanksDetails = `/order/page/thanks`
export const uploadOrderImage = `/order/image/`
export const sendOrderEmail = `/testemails/send_orderdetail_email?id=`

/* Outlet */
export const getOutlets = `/outlet`;

/* Filter */
const filter = `/filters/`;

export const getFilterRooms = filter + "rooms";
export const getFilterStyles = filter + "styles";
export const getFilterMaterials = filter + "materials";
export const getFilterLocations = filter + "locations";
export const getFilterBrands = filter + "brands";
export const getFilterCategories = filter + "categories";