import React from 'react';

import './index.scss'

const CheckBox = ({
    label,
    parentClassProp='container text-center',
    name,
    checked,
    setChecked,
    errorState
}) => {
    return (      
        <div className={parentClassProp}>
            <label for={name} className = "flex items-center ">
                {checked && (
                    <div className = "checkbox-checked mr-[10px]" onClick = {() => setChecked(!checked)}>
                        <span className = "checkbox-checked-content fade-in-bck"></span>
                    </div>
                )}
                {!checked && (
                    <div className = "checkbox-unchecked mr-[10px]" onClick = {() => setChecked(!checked)}>
                        <span className = "checkbox-unchecked-content fade-in-bck"></span>
                    </div>
                )}
                <span className ={"text-[#8D908B] text-[16px] w-full" + (errorState ? " input-check-error" : "")}>{label}</span>
            </label>
        </div>
)}

export default CheckBox;
