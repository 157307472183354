import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import { useSelector, connect } from "react-redux";
import { jobApplicationEmail } from "../../../redux/actions/email";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbsItem from "../../../components/breadcrumb/breadcrumb";
import ChooseFile from "../../../components/choose_file/choose_file";
import RoundedButton from "../../../components/rounded_button/rounded_button";
import { getJob } from "../../../redux/actions/vacatures";
import { jobApplicationFiles, jobApplicationFields } from "../constant";
import "./application.scss";
import ApplicationForm from "./applicationForm";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import SuccessModal from "../../../components/success_modal/success_modal";
import { REGEX } from "../../../constants/constants";

function useWindowSize() {
  const [size, setSize] = useState([0]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const JobApplication = (props) => {
  let { applicationId } = useParams();
  const jobSelector = useSelector((state) => state.vacatures.job);
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [jobFiles, setJobFiles] = useState([]);
  const [fields, setFields] = useState([]);
  const [successModalState, setSuccessModalState] = useState(false)

  const options = {
    icons: {},
    items: [
      { to: "/", label: "Home" },
      { to: "/job-openings", label: "Vacatures" },
      { to: "/", label: `${job?.name}`, active: true },
    ],
  };
  const isMobile = useWindowSize() < 1025;
  const Heading = ({ label, mb, mainHeadings = true }) => {
    const props = mainHeadings
      ? `ms:text-[40px] text-[35px] font-dmserif mb-[${mb}] text-[#404040]`
      : `ms:text-[27px] text-[20px] font-semibold font-montserrat mb-[${mb}] text-[#C98468]`;
    return <div className={props}>{label}</div>;
  };

  useEffect(() => {
    setFields([...jobApplicationFields]);
    setJobFiles([...jobApplicationFiles]);
  }, []);

  useEffect(() => {
    setJob(jobSelector);
  }, [jobSelector]);

  useEffect(() => {
    props.getJob(applicationId);
  }, [applicationId]);

  const hanldeChangeFields = (e) => {
    e.stopPropagation();
    const { id, name: parentId, value } = e.target;
    let items = [...fields];
    if (Array.isArray(items[parentId]?.fields)) {
      items[parentId].fields[id].value = value;
      setFields([...items]);
    } else {
      items[id].value = value;
      setFields([...items]);
    }
  };

  const hanldeChangeFiles = ({ target: { id, name, value } }) => {
    let items = [...jobFiles];
    items[id].value = value;
  };

  const fileHandler = (e) => {
    const { id } = e.target;
    const fileUploaded = e.target.files[0];
    let items = [...jobFiles];
    let allFiles = [];
    items[id].description = fileUploaded.name;
    items[id].value = fileUploaded;
    items.forEach((item) => {
      allFiles.push(item.value);
    });
    let items2 = [...fields];
    items2[6].value = allFiles;
    setJobFiles([...items]);
  };

  const handleSubmit = (e) => {
    if(validateForm()) {
      let data = {};
      let docs = [];
      fields.forEach((field) => {
        if (field.fields) {
          data[`${field.name}`] = field["fields"];
        } else if (field.name === "documents") {
          docs = [...field["value"]];
        } else {
          data[`${field.name}`] = field["value"];
        }
      });
      data[`job_description`] = job;
      props.jobApplicationEmail(data, docs).then(response => {
        setSuccessModalState(true)  
      });
    }
  };

  const onSuccessModalTimeout = () => {
    setFields([
      {
        id: 0,
        label: "NAAM",
        name: "name",
        value: null,
        placeholder: "Naam",
        error: false,
        errorMsg: "",
      },
      {
        id: 1,
        label: "VOORNAAM",
        name: "firstName",
        value: null,
        placeholder: "Naam",
        error: false,
        errorMsg: "",
      },
      {
        id: 2,
        label: "ADRES",
        name: "address",
        fields: [
          { id: 0, value: null, name: "Straat" },
          { id: 1, value: null, name: "Nr" },
          { id: 2, value: null, name: "Bus" },
          { id: 3, value: null, name: "Gemeente" },
          { id: 4, value: null, name: "Postcode" },
        ],
        error: false,
        errorMsg: "",
      },
      {
        id: 3,
        label: "EMAIL",
        name: "email",
        value: null,
        placeholder: "Example@email.com",
        error: false,
        errorMsg: "",
      },
      {
        id: 4,
        label: "GSM NUMMER",
        name: "number",
        value: null,
        placeholder: "+32",
        error: false,
        errorMsg: "",
      },
      {
        id: 5,
        label: "GEBOORTE DATUM",
        name: "date",
        value: null,
        placeholder: "DD/MM/jjjj",
        error: false,
        errorMsg: "",
      },
      {
        id: 6,
        label: "Documents",
        name: "documents",
        value: [],
        placeholder: "",
        error: false,
        errorMsg: "",
      }
    ]);
    setJobFiles([{
      id: 0,
      btnTitle: "Kies bestand",
      value: null,
      description: "Upload uw CV (sterk aanbevolen)",
      error: false,
      errorMsg: "",
    },
    {
      id: 1,
      btnTitle: "Kies bestand",
      value: null,
      description: "Upload uw motivatiebrief (sterk aanbevolen)",
      error: false,
      errorMsg: "",
    },
    {
      id: 2,
      btnTitle: "Kies bestand",
      value: null,
      description: "Upload uw ID (sterk aanbevolen)",
      error: false,
      errorMsg: "",
    },
    {
      id: 3,
      btnTitle: "Kies bestand",
      value: null,
      description: "Upload uw foto",
      error: false,
      errorMsg: "",
    }])
    setSuccessModalState(false)
  }

  const validateForm = () => {
    let isValid = true;
    let fieldsCopy = JSON.parse(JSON.stringify(fields));
    if(fields?.length > 0) {
      fields.forEach((field, fieldIndex) => {
        if(mandatoryFields(field?.label)) {
          const fieldValid = testMandatoryFields(field?.value, field?.label === "EMAIL" ? "email" : field?.label === "GSM NUMMER" ? "phone" : "string")
          if(!fieldValid) {
            isValid = false;
          }
          fieldsCopy[fieldIndex].error = !fieldValid;
        }
      })
    }
    // updates the error values
    !isValid && window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    })
    setFields(fieldsCopy)
    return isValid;
  }

  const mandatoryFields = (value) => {
    return value === "NAAM" ? true : value === "VOORNAAM" ? true : value === "EMAIL" ? true : value === "GSM NUMMER" ? true : false;
  }

  const testMandatoryFields = (value, type) => {
    if(type === "email") {
      return REGEX.EMAIL.test(value) ? true : false;
    }
    else if(type === "phone") {
      return REGEX.PHONE.test(value) ? true : false;
    }
    else {
      return value ? true : false;
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="mb-5"
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="jobs-container pb-30">
          <BreadcrumbsItem options={options} />
          <Container>
            <div className="text-center pb-10">
              <span className="fs-35 font-dmserif text-[#404040]">
                Vacatures
              </span>
            </div>
          </Container>
        </div>
        <Container>
          <div className="mt-8 cursor-pointer">
            <span className="text-[#B96A4B]">{"< "}</span>
            <span
              className="application-back-page font-[Montserrat] fs-16"
              onClick={() => navigate(`/job-openings`)}
            >
              {"Terug naar alle vacatures"}
            </span>
          </div>
        </Container>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-14">
        <Container>
          <div className="application-main-container">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="application-container p-[10px]"
              >
                <div>
                  <span className="font-[SerifDisplay] fs-30 text-[#404040]">
                    {job?.name}
                  </span>
                  <br />
                  <span className="font-[Montserrat] fs-16 text-[#27292C]">
                    <div className="linkDiv">
                      <div
                        dangerouslySetInnerHTML={{ __html: job?.description }}
                      />
                    </div>
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className="application-container p-[10px]"
              >
                <div>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={2}>
                        <IoLocationOutline size="1.75em" color="#C98468" />
                      </Grid>
                      <Grid item xs={10}>
                        <span className="pl-[5px] font-[Montserrat] fs-16 font-bold capitalize">
                          {job?.location || 'Izegem'}
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <IoLocationOutline size="1.75em" color="#C98468" />
                      <span className="font-[Montserrat] fs-16 font-bold capitalize">
                        {job?.location || 'Izegem'}
                      </span>
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className="application-container p-[10px]"
              >
                <div>
                  {isMobile ? (
                    <Grid container>
                      <Grid item xs={2}>
                        <IoMdTime size="1.75em" color="#C98468" />
                      </Grid>
                      <Grid item xs={10}>
                        <span className="pl-[5px] font-[Montserrat] fs-16">
                          {job?.regime}
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <IoMdTime size="1.75em" color="#C98468" />
                      <span className="font-[Montserrat] fs-16">
                        {job?.regime}
                      </span>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Grid>
      <Container>
        <div className="application-form-section md:mx-[6%] mx-[0px] mt-[40px]">
          <ApplicationForm
            fields={fields}
            hanldeChangeFields={hanldeChangeFields}
            isMobile={isMobile}
          />
          <div className="mt-8">
            {jobFiles?.map((job, index) => (
              <ChooseFile
                id={index}
                description={job.description}
                btnTitle={job.btnTitle}
                fileHandler={fileHandler}
              />
            ))}
          </div>
          <div className="mt-12">
            <RoundedButton title={"Verzenden"} handler={handleSubmit} />
          </div>
        </div>
      </Container>
      <SuccessModal modalState = {successModalState} message = "Succes!" onTimeout = {onSuccessModalTimeout} timeoutDuration = {4000} />
    </Grid>
  );
};

export default connect(null, { jobApplicationEmail, getJob })(JobApplication);
