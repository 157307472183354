import React, { useEffect, useState } from 'react';
import { Grid, Container } from "@mui/material";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

import CheckBox from '../../../components/inputs/checkbox';
import SubmitButton from '../../../components/submit_section';
import axios from "axios";
import { BASE_URL, getAdvanceDetails } from '../../../redux/constants/ApiEndPoints';
import Terms from "./components/terms";

import './deposit.scss';

const DepositPage = (props) => {
    const [checked1, setChecked1] = React.useState(false)
    const [checked2, setChecked2] = React.useState(false)
    const [checked3, setChecked3] = React.useState(false)
    const [ advanceDetailState, setAdvanceDetailState ] = useState({})
    const [ termsModalState, setTermsModalState ] = useState(false)
    const [checkboxOneErrorState, setCheckboxOneErrorState] = useState(false);
    const [checkboxThreeErrorState, setCheckboxThreeErrorState] = useState(false);

    const { onClick, advanceamount } = props;
    const checkboxParentDivClassProp = 'container text-left mb-[15px]'

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {
        axios.get(BASE_URL + getAdvanceDetails, {headers}).then(response => {
            setAdvanceDetailState(response.data.data)
        }).catch(error => console.log(error))
    }, [])

    const getAdvanceAmount = () => {
        if(advanceDetailState?.percentage) {
            const advance = advanceamount * advanceDetailState?.percentage;
            return advance?.toFixed(2);
        }
        else {
            return 0;
        }
    }

    const handleSubmit = (e) => {
        if(checked1 && checked3) {
            onClick(checked2);
        }
        else {
            // show red 
            if(!checked1) {
                setCheckboxOneErrorState(true);
            }
            else {
                setCheckboxOneErrorState(false);
            }
            if(!checked3) {
                setCheckboxThreeErrorState(true);
            }
            else {
                setCheckboxThreeErrorState(false)
            }
        }
    };
    
    const handleTermsModalOpen = () => {
        setTermsModalState(true)
    }

    const handleTermsModalClose = () => {
        setTermsModalState(false)
    }

    return (
        <>
            <Grid container justifyContent= "center" alignItems = "center" className='text-[#404040]'>
                <Container maxWidth = "lg">
                    <div className='w-full text-center'>
                        <div className='heading_deposit font-dmserif my-[40px]'>
                            {advanceDetailState?.title}
                        </div>
                    </div>
                    <div className='font-montserrat text_deposit text-[#8D908B] mb-[30px]'>
                        <div className="linkDiv">
                            {ReactHtmlParser(advanceDetailState?.text)}
                        </div>
                    </div>
                    <div className='font-montserrat py-[14px] options_deposit px-[22px] font-[18px] mb-[28px] font-bold bg-[#FAFAFA] w-full h-[50px]'>
                        <span className='float-left'>Voorshot {advanceDetailState?.percentage}%</span>
                        <span className='float-right text-[#C98468]'>€ {getAdvanceAmount()}</span>
                    </div>
                    <div className='pl-[20px]'>
                        <CheckBox
                            label='Uw gegevens worden enkel gebruikt voor de praktische afhandeling van uw bestelling'
                            name='checkbox_1'
                            parentClassProp={checkboxParentDivClassProp}
                            checked={checked1}
                            setChecked={setChecked1}
                            errorState = {checkboxOneErrorState}
                        />
                        <CheckBox
                            label='U wenst op de hoogte te worden gehouden van promoties vie emailing'
                            parentClassProp={checkboxParentDivClassProp}
                            name='checkbox_2'
                            checked={checked2}
                            setChecked={setChecked2}
                        />
                        <CheckBox
                            label={<span>Door dit vakje aan te vinken, accepteer ik de <span className='underline cursor-pointer' onClick = {handleTermsModalOpen}>algemene verkoopvoorwaarden</span> van Top Interieur</span>}
                            parentClassProp={checkboxParentDivClassProp}
                            name='checkbox_3'
                            checked={checked3}
                            setChecked={setChecked3}
                            errorState = {checkboxThreeErrorState}
                        />
                    </div>
                    <SubmitButton 
                        mtSubmitSection='60px'
                        submitText='Volgende'
                        link = "/checkout/confirmation"
                        onClick = {handleSubmit}
                        alternative
                    />
                </Container>
            </Grid>
            <Terms modalState = {termsModalState} onClose = {handleTermsModalClose} />
        </>
    )

}

export default DepositPage;

DepositPage.defaultProps = {
    onClick: () => {},
    advanceamount: 0
}

DepositPage.propTypes = {
    onClick: PropTypes.func,
    advanceamount: PropTypes.number
}
