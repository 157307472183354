import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LoginTopBar from "../../components/top_bars/login_top_bar";
import RadioButton from "../../components/radio_button/radio_button";
import PropTypes from "prop-types";
import Login from "../authentication/login";
import AccountPage from "./account_page/account_page";
import Delivery from "./delivery_pickup/index";
import Deposit from "./deposit";
import OrderPlaced from "./order_placed/order_placed";
import axios from "axios";
import { BASE_URL, createOrder, getCart, updateCart, uploadOrderImage, sendOrderEmail } from "../../redux/constants/ApiEndPoints";

import "./checkout.scss";
import SuccessModal from "../../components/success_modal/success_modal";

export default function Checkout(props) {
    const [ userCart, setUserCart ] = useState({});
    const [ breadcrumbState, setBreadcrumbState ] = useState([0]);
    const [ breadcrumbDisplayState, setBreadcrumbDisplayState ] = useState(true);
    const [ billingData, setBillingData ] = useState([]);
    const [ deliveryData, setDeliveryData ] = useState({});
    const [ amountState, setAmountState ] = useState({
        total: 0,
        advance: 0
    })
    const [ modalState, setModalState ] = useState(false);
    const [ uploadPercentageState, setUploadPercentageState ] = useState(0);
    
    const userData = JSON.parse(localStorage.getItem("user-data"))
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { type } = props;

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    useEffect(() => {
        if(userData?.id) {
            axios.get(BASE_URL + getCart + userData?.id,{headers}).then(response => {
                // if cart is empty then redirect user to cart page
                if(response.data?.data?.products && response?.data?.data?.products?.length > 0) {
                    setUserCart(response.data.data);
                }
                else {
                    if(!location.pathname.includes("checkout/order")) {
                        navigate("/cart")
                    }
                }
            }).catch(error => console.log(error))
        }
    }, [])

    useEffect(() => {
        if(userData?.id) {
            // if user is logged in check url params
            if(type === "login") {
                setBreadcrumbState([0])
                setBreadcrumbDisplayState(true)
            }
            else if(type === "billing") {
                setBreadcrumbState([0, 1])
                setBreadcrumbDisplayState(true)
            }
            else if(type === "delivery") {
                setBreadcrumbState([0, 1, 2])
                setBreadcrumbDisplayState(true)
            }
            else if(type === "confirmation") {
                setBreadcrumbState([0, 1, 2, 3])
                setBreadcrumbDisplayState(true)
            }
            else if(type === "order") {
                setBreadcrumbDisplayState(false)
            }
        }
        else {
            // if user isn't logged in, navigate back to login page
            navigate("/checkout/login")
        }
    }, [type])

    useEffect(() => {
        if(userCart?.id) {
            let totalwobtw = 0;
            userCart?.products?.forEach(product => {
                totalwobtw += getItemPrice(product) * product?.quantity || 1;
            })
            const total = totalwobtw;
            const advance = (total/100);
            setAmountState({total, advance});
        }
    }, [userCart])

    const getItemPrice = (cartItem) => {
        if(cartItem?.type === 1) {
            return cartItem?.product?.promotion_price || cartItem?.product?.price || 0;
        }
        else {
            return cartItem?.sale_item?.new_sales_price || cartItem?.sale_item?.original_sales_price || 0;
        }
    }

    const orderSumbit = (news_letter) => {
        if(userCart?.id && userCart?.products?.length > 0) {
            // check if a cart even exists
            const address = [...billingData];
            const { floor, house_id, isElevator, isSpacious, delivery, outlet_id, suggestions, files } = deliveryData;
            const { total } = amountState;
            const order_details = [];
            userCart?.products?.forEach(product => {
                if(product?.type === 1) {
                    order_details.push({
                        product_id: product?.product_id,
                        quantity: product?.quantity
                    })
                }
                else {
                    order_details.push({
                        sale_item_id: product?.sale_item_id,
                        quantity: product?.quantity
                    })
                }
            })

            const payload = {
                user_id: userData?.id,
                outlet_id: delivery ? null : outlet_id,
                delivery: delivery,
                floor_number: floor,
                house_type: house_id,
                isElevator,
                isSpacious,
                advance_payment: false,
                suggestions,
                address,
                order_details,
                total,
                news_letter
            }

            const orderImagesPayload = new FormData();
            if(files && files?.length > 0) {
                files.forEach(fileItem => {
                    orderImagesPayload.append("image", fileItem);
                })
            }

            axios.post(BASE_URL + createOrder, payload, {headers}).then(orderResponse => {
                // now we remove the items from the cart
                axios.put(BASE_URL + updateCart + userCart?.id, {
                    id: userCart?.id,
                    user_id: userCart?.user_id,
                    products: []
                },{headers}).then(response => {
                    
                }).catch(error => console.log(error))

                orderImagesPayload.append("order_id", orderResponse.data.data.id)
                if(files?.length > 0) {
                    // files exist, upload then route
                    setModalState(true)
                    axios.post(BASE_URL + uploadOrderImage, orderImagesPayload, {
                        onUploadProgress: uploadProgress => {
                            const { loaded, total } = uploadProgress;
                            const percentage = Math.floor((loaded * 100) / total);
                            setUploadPercentageState(percentage);
                        },
                        headers
                    }).then(response => {
                        // after image uploading is done, send email
                        axios.get(BASE_URL + sendOrderEmail + orderResponse?.data?.data?.id,{headers}).then(response => {
                            setModalState(false)
                            navigate(`/checkout/order/${orderResponse.data.data?.id}`);
                            navigate(0)
                        }).catch(error => console.log(error))
                    }).catch(error => console.log(error))     
                }
                else {
                    // no files exist, no reason to upload so just send order email
                    axios.get(BASE_URL + sendOrderEmail + orderResponse?.data?.data?.id,{headers}).then(response => {
                        console.log(response.data)
                        navigate(`/checkout/order/${orderResponse.data.data?.id}`);
                    }).catch(error => console.log(error))
                }
            }).catch(error => console.log(error))
        }
        else {
            // navigate back to cart page since no cart exists or no products in cart exists
        }
        
    }

    return(
        <div>
            <Grid container justifyContent= "center" alignItems = "center">
                <Container maxWidth = "lg">
                    <RadioButton activeProps={breadcrumbState} enabled = {breadcrumbDisplayState} />
                    {type === "login" && (
                        <Login navigationPath = "/checkout/billing" />
                    )}
                    {type === "billing" && (
                        <AccountPage setBillingData = {setBillingData} />
                    )}
                    {type === "delivery" && (
                        <Delivery setDeliveryData = {setDeliveryData} />
                    )}
                    {type === "confirmation" && (
                        <Deposit onClick = {orderSumbit} advanceamount = {amountState?.advance || 0} />
                    )}
                    {type === "order" && (
                        <OrderPlaced id = {id} />
                    )}
                </Container>
            </Grid>
            <SuccessModal modalState = {modalState} message = {"Uploading Files" + ` ${uploadPercentageState}%`} />
        </div>
    )
}

AccountPage.propTypes = {
    setBillingData: PropTypes.func
}

AccountPage.defaultProps = {
    setBillingData: () => {}
}

Deposit.propTypes = {
    onClick: PropTypes.func,
    advanceamount: PropTypes.number
}

Deposit.defaultProps = {
    onClick: () => {},
    advanceamount: 0
}

Delivery.propTypes = {
    setDeliveryData: PropTypes.func
}

Delivery.defaultProps = {
    setDeliveryData: () => {}
}

Checkout.propTypes = {
    type: PropTypes.string
}

Checkout.defaultProps = {
    type: "login"
}

Login.propTypes = {
    navigationPath: PropTypes.string
}

Login.defaultProps = {
    navigationPath: ""
}